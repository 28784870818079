/**
 * DOM helpers.
 */

import { is } from './helpers';

// Not using createElement, only checking if it exists.
// eslint-disable-next-line @typescript-eslint/unbound-method
export const canUseDOM = is.func(globalThis.document?.createElement);

/**
 * Get the element that currently has focus, if any.
 */
export function getFocusedElement(): Element | null {
	const focused = document.activeElement;
	// If there is no candidate node, activeElement falls back to the body and
	// the document. Ignore those since they're never relevant when doing focus
	// management.
	// https://html.spec.whatwg.org/multipage/interaction.html#focus-management-apis
	return focused === document.body || focused instanceof Document
		? null
		: focused;
}

// CSS selector for elements that can be tabbed to.
//
// Based on https://github.com/KittyGiraudel/focusable-selectors.
// See also https://github.com/focus-trap/tabbable.
//
// JSDom doesn't support inert attribute as of December 2023, leading to
// syntax error when testing.
const notInert =
	process.env.NODE_ENV === 'test' ? '' : ':not([inert]):not([inert] *)';
const notNegTabIndex = ':not([tabindex^="-"])';
const notDisabled = ':not(:disabled)';
export const tabbableSelector = [
	`a[href]${notInert}${notNegTabIndex}`,
	// `area[href]${notInert}${notNegTabIndex}`,
	`audio[controls]${notInert}${notNegTabIndex}`,
	`video[controls]${notInert}${notNegTabIndex}`,
	`button${notInert}${notNegTabIndex}${notDisabled}`,
	`select${notInert}${notNegTabIndex}${notDisabled}`,
	`textarea${notInert}${notNegTabIndex}${notDisabled}`,
	`input:not([type="hidden"])${notInert}${notNegTabIndex}${notDisabled}`,
	`details${notInert} > summary:first-of-type${notNegTabIndex}`,
	`iframe${notInert}${notNegTabIndex}`,
	// `embed${notInert}${notNegTabIndex}`,
	// `object${notInert}${notNegTabIndex}`,
	`[contenteditable]${notInert}${notNegTabIndex}`,
	`[tabindex]${notInert}${notNegTabIndex}`,
].join(', ');
export const nonTabbableClasses = ['hidden', 'invisible'] as const;

interface TabbableFilterOptions {
	checkClasses?: boolean;
	checkSize?: boolean;
}

/**
 * Filter a seemingly tabbable element for things that makes it untabbable.
 *
 * The `checkSize` option excludes elements that have a width and height of
 * zero pixels, which may be useful or wrong depending on the situation.
 *
 * - A zero sized element (e.g. width: 0; height: 0; overflow: hidden;) will
 *   be excluded but can still receive tab focus = could be bad.
 * - A child of a parent with display: none; will be excluded and can NOT
 *   recieve focus = probably good.
 *
 * A more correct approach would be checking for visibility up the parent tree,
 * but that would likely be extremely expensive. Instead consider if size
 * checking is needed on a case-by-case basis.
 */
function isTabbableFilter(
	element: HTMLElement,
	{ checkClasses = true, checkSize = false }: TabbableFilterOptions = {},
) {
	/* eslint-disable sonarjs/prefer-single-boolean-return */
	if (
		checkClasses &&
		nonTabbableClasses.some((className) =>
			element.classList.contains(className),
		)
	) {
		return false;
	}
	if (checkSize && element.offsetHeight === 0 && element.offsetWidth === 0) {
		return false;
	}
	return true;
}

/**
 * Select all tabbable elements contained in the specified one.
 *
 * @see isTabbableFilter
 */
export function selectTabbable<T extends HTMLElement>(
	inElement: HTMLElement,
	opt?: TabbableFilterOptions,
): T[] {
	return [...inElement.querySelectorAll<T>(tabbableSelector)].filter((el) =>
		isTabbableFilter(el, opt),
	);
}

/**
 * Check if an element can be navigated to with the tab key.
 *
 * @see isTabbableFilter
 */
export function isTabbable(
	element: HTMLElement,
	opt?: TabbableFilterOptions,
): boolean {
	return element.matches(tabbableSelector) && isTabbableFilter(element, opt);
}

/**
 * Check if an element can receive focus.
 */
export function isFocusable(element: HTMLElement): boolean {
	// The tabbable selector excludes negative tabindex but that's relevant for
	// programmatic focus.
	return element.hasAttribute('tabindex') || element.matches(tabbableSelector);
}

/**
 * Get the current window width, including scrollbar.
 */
export function getWindowWidth() {
	return document.documentElement.clientWidth;
}

/**
 * Get the current window height, including scrollbar.
 */
export function getWindowHeight() {
	return document.documentElement.clientHeight;
}

/**
 * Scroll to an element if it's outside the viewport.
 */
export function scrollIntoViewIfNeeded<T extends HTMLElement>(
	elem: T,
	{
		// Default to 95% intersection, some quick testing shows that 100% is not
		// always reached even if the element is fully visible.
		doneIntersectionThreshold = 0.95,
		onDone,
	}: { doneIntersectionThreshold?: number; onDone?: (elem: T) => void },
) {
	// Element's top edge position relative to the viewport. Zero means the top
	// edge is right on the top of the current view.
	const { bottom: elemBottom, top: elemTop } = elem.getBoundingClientRect();
	// Not completely true but close enough - if CSS smooth scrolling is
	// supported by the browser, assume the options object for scrollIntoView is
	// too. Passing an object where not supported will just result in it being
	// interpreted as truthy rather than an error, which means the scrolling
	// will be 'top based'. More of a UX quirk than an actual problem.
	const supportsOptions = 'scrollBehavior' in document.documentElement.style;

	const observeDone = () => {
		if (onDone) {
			const observer = new IntersectionObserver(
				([entry]) => {
					if (entry?.isIntersecting) {
						onDone(entry.target as T);
						observer.disconnect();
					}
				},
				{ threshold: [doneIntersectionThreshold] },
			);
			observer.observe(elem);
		}
	};

	if (elemTop < 0) {
		observeDone();
		// Element is above the current viewport, scroll up to top alignment.
		elem.scrollIntoView(supportsOptions ? { behavior: 'smooth' } : true);
	} else if (elemBottom > getWindowHeight()) {
		observeDone();
		// Element is fully below the current viewport, scroll down to bottom
		// alignment.
		elem.scrollIntoView(
			supportsOptions ? { behavior: 'smooth', block: 'end' } : false,
		);
	}
}

/**
 * Scroll to the specified element, putting its top edge at the window's top.
 *
 * The default `header` offset value accounts for the fixed header. A custom
 * number can also be used.
 */
export function scrollTo(
	el: HTMLElement,
	{
		offset = 'header',
		smooth = true,
	}: { offset?: number | 'header'; smooth?: boolean } = {},
) {
	// Using an approximate measurement for the fixed header height, it doesn't
	// have to be exact and hardcoding a number makes it much simpler.
	const topOffset = offset === 'header' ? -80 : offset;
	window.scrollTo({
		behavior: smooth ? 'smooth' : 'auto',
		top: el.getBoundingClientRect().top + window.pageYOffset + topOffset,
	});
}

let scrollbarWidthCache: number | undefined;

/**
 * Get the width of scrollbars in the current browser. Only counts scrollbars
 * that affect layout, so overlay scrollbars have zero width.
 */
export function getScrollbarWidth(): number {
	// Do nothing on the server or if the body isn't ready yet.
	if (!canUseDOM || !document.body) {
		return 0;
	}

	if (is.number(scrollbarWidthCache)) {
		return scrollbarWidthCache;
	}

	const refSize = 100;
	const el = document.createElement('div');
	el.style.position = 'absolute';
	el.style.visibility = 'hidden';
	el.style.overflow = 'scroll';
	el.style.width = `${refSize}px`;
	el.style.height = `${refSize}px`;

	document.body.append(el);

	// clientWidth includes padding but excludes borders, margins, and
	// vertical scrollbars.
	scrollbarWidthCache = refSize - el.clientWidth;

	el.remove();

	return scrollbarWidthCache;
}

/**
 * Focus and scroll to (unless disabled) the element with the specified ID.
 */
export function goToElement(
	elementId: string,
	{
		scroll = true,
		strict = false,
	}: {
		/** Scroll target element to the top of the window? */
		scroll?: boolean;
		/** Throw an error if there is no element for specified ID? */
		strict?: boolean;
	} = {},
) {
	const el = document.getElementById(elementId);
	if (!el) {
		if (strict) {
			throw new Error(`Missing link target element with ID '${elementId}'`);
		}
		return;
	}
	if (!isFocusable(el)) {
		el.tabIndex = -1;
	}
	if (scroll) {
		scrollTo(el);
	}
	el.focus();
}
