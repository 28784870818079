import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Img from 'components/Img';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAsset } from 'models/asset';
import type { HTMLString } from 'types';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		content: Field<HTMLString>;
		heading: Field<string>;
		svgImage?: Field<DigizuiteAsset[]>;
	};
};

export default function CreditWarning({ fields }: Props) {
	if (!fields) {
		return null;
	}
	const iconSrc = getAsset('Source Copy', fields.svgImage?.value)?.src;
	return (
		<div className="flex">
			{iconSrc && (
				<Img
					src={iconSrc}
					alt=""
					className="mr-2 h-[4.5em] w-auto shrink-0"
					width={67}
					height={67}
					service="nextjs"
					jpgOptimized={false}
				/>
			)}
			<div>
				<Text className="font-bold" as="pSmall" field={fields.heading} />
				<RichText className="text-sm" field={fields.content} />
			</div>
		</div>
	);
}
CreditWarning.displayName = 'CreditWarning';
