/**
 * CartListItem
 */

import React from 'react';

import Button from 'components/Button';
import { ListItemStockInfo } from 'components/CartListItem';
import CartQuantity from 'components/CartQuantity';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { OptionalLink } from 'components/Link';
import Price from 'components/Price';
import Staffling from 'components/Staffling';
import Text from 'components/Text';
import { useGlobalPopoversContext, usePriceContext } from 'contexts';
import type { CartError, RowSummary } from 'models/api';
import type { CustomizationPlacement } from 'models/api/ProductCustomization';
import type { Price as PriceModel } from 'models/price';
import { getFormattedStockAmount } from 'utils/business-logic/stock';
import { cn } from 'utils/classNames';
import { formatPriceText } from 'utils/format';
import { getTestDataAttrFrom, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface SelectedStoreStock {
	/** The available stock level of the store */
	availableStockLevel: number;
	/** The category color of the stock */
	categoryColor: string;
	/** The category name of the stock */
	categoryName: string;
	/** If the store is in stock */
	inStock: boolean;
	/** The name of the store */
	name: string;
	/** The primary location of the store */
	primaryLocation?: string;
}

interface WebStock {
	/** The available stock level */
	availableStockLevel: number;

	/** If the stock is in stock */
	inStock: boolean;

	/** If the stock is restocking */
	reStockWithinAcceptableTimespan: boolean;
}

interface Props {
	allStoresInStockCount?: number;

	/** Errors for variant */
	errors?: CartError[];
	/** The heading in the component */
	heading: string | undefined;

	id: string | undefined;
	/** Image alt text */
	imageAlt: string;
	/** Image src attribute */
	imageSrc: string;
	invalidQuantity?: boolean;
	/** The link to the product page */
	link: string | undefined;
	moveVariantToCartButtonText?: string;
	moveVariantToWishlistButtonText?: string;
	onMoveVariantToCart?: (() => void) | undefined;
	onMoveVariantToWishlist?: (() => void) | undefined;
	onRemoveVariant?: () => void;
	/** Function to update cart */
	onUpdateVariantQty?: (qty: number) => void;
	/** Print placements added to the product */
	placements?: CustomizationPlacement[];
	/** The item's main price */
	price: PriceModel;

	/** Product quantity in cart */
	quantity: number;

	/** If total row sum is a reduced price */
	reducedPrice?: boolean;
	removeVariantButtonText?: string;

	/** The total sum of the product row */
	rowSummary?: RowSummary;

	selectedStoreStock?: SelectedStoreStock;

	volumePrice: PriceModel | undefined;

	webStock: WebStock | undefined;
}

/** The cart list item component. */
export default function CartListItem({
	allStoresInStockCount,
	errors,
	heading,
	id,
	imageAlt,
	imageSrc,
	invalidQuantity = false,
	link,
	moveVariantToCartButtonText,
	moveVariantToWishlistButtonText,
	onMoveVariantToCart,
	onMoveVariantToWishlist,
	onRemoveVariant,
	onUpdateVariantQty,
	placements,
	price,
	quantity,
	reducedPrice,
	removeVariantButtonText,
	rowSummary,
	selectedStoreStock,
	volumePrice,
	webStock,
}: Props) {
	const { t } = useI18n();
	const { canTogglePriceVat, usePriceExcVat } = usePriceContext();
	const { openStoreSelector } = useGlobalPopoversContext();

	const allStoresStockTextRaw = t('stock_stores_amount_exact', {
		amount: allStoresInStockCount,
	});

	const rowSum = usePriceExcVat
		? rowSummary?.priceExcVat
		: rowSummary?.priceIncVat;

	const allStoresStockText = allStoresStockTextRaw.endsWith('.')
		? allStoresStockTextRaw
		: `${allStoresStockTextRaw}.`;
	const allStoresStockTextButton = (
		<Button
			variant="text-inline"
			size="small"
			underline={false}
			className="group/stock-btn min-h-4 py-0"
			hasExpandedHitArea
			onClick={() => {
				if (id) {
					openStoreSelector(id);
				}
			}}
		>
			{allStoresStockText}&nbsp;
			<span className="inline-block underline group-hover/stock-btn:no-underline">
				{t('select_store_button_text')}
			</span>
		</Button>
	);
	const titleId = `cart-list-item-title-${id}`;

	return (
		<article
			key={id}
			aria-labelledby={titleId}
			className="flex py-6 print:break-inside-avoid print:py-4"
		>
			<OptionalLink href={link} className="shrink-0" isScreenReaderHidden>
				<Img
					className="mb-1 mr-4 size-16 object-contain md:size-24"
					src={imageSrc || '/assets/images/placeholder-image.png'}
					alt={imageAlt}
					width={96}
					height={96}
					service="nextjs"
				/>
			</OptionalLink>
			<div className="grow">
				<header>
					<OptionalLink
						href={link}
						id={titleId}
						className="inline-block pb-1 font-bold hover:underline"
					>
						{heading}
					</OptionalLink>
					{!selectedStoreStock && (
						<Text as="pSmall">
							{t('cart_article_number_label')} {id}
						</Text>
					)}
				</header>
				<div className="mt-4 flex grow max-md:flex-col md:items-start md:justify-between print:mt-1 print:flex-row print:items-start print:justify-between">
					<div className={selectedStoreStock ? 'md:w-1/3' : 'md:w-1/2'}>
						{is.arrayWithLength(placements) && (
							<ul className="mb-6 flex flex-col gap-4">
								{placements.map((placement) => (
									<li key={placement.id}>
										<Text as="pSmall" text={placement.title} />
										<Text as="pSmall" text={placement.option?.text} />
										<Text
											as="pSmall"
											text={placement.printImageFilename?.originalFileName}
										/>
									</li>
								))}
							</ul>
						)}
						<Price price={price} size="mini" />
						{canTogglePriceVat && (
							<Text
								className="mt-1"
								text={t(
									usePriceExcVat
										? 'price_vat_excluded_text'
										: 'price_vat_included_text',
								)}
								as="pXSmall"
							/>
						)}
						<div className="mt-4 flex flex-col gap-y-2 print:gap-0">
							{webStock && (
								<ListItemStockInfo
									inStock={webStock?.inStock}
									restock={webStock?.reStockWithinAcceptableTimespan}
									inStockText={t('stock_online_amount_exact', {
										amount: getFormattedStockAmount(
											webStock?.availableStockLevel,
										),
									})}
									outOfStockText={t('stock_online_out_of_stock_general')}
									reStockText={t('stock_online_re_stock')}
								/>
							)}
							{selectedStoreStock && (
								<ListItemStockInfo
									inStock={Boolean(selectedStoreStock?.inStock)}
									inStockText={t('stock_store_name_amount_exact', {
										store: selectedStoreStock?.name,
										amount: getFormattedStockAmount(
											selectedStoreStock?.availableStockLevel,
										),
									})}
									outOfStockText={t('stock_store_out_of_stock_general', {
										store: selectedStoreStock?.name,
									})}
								/>
							)}
							{!selectedStoreStock && is.defined(allStoresInStockCount) && (
								<ListItemStockInfo
									inStock={allStoresInStockCount > 0}
									inStockText={allStoresStockTextButton}
									outOfStockText={allStoresStockTextButton}
								/>
							)}
						</div>
					</div>
					{selectedStoreStock && (
						<div
							className="py pl-2 max-md:mt-6 md:w-1/3 print:mt-0"
							style={{
								borderLeft: `4px solid ${selectedStoreStock.categoryColor}`,
							}}
						>
							<Text
								as="p"
								text={`${t(
									'product_details_store_accordion_department_text',
								)} ${selectedStoreStock.categoryName}`}
							/>
							<Text
								as="p"
								text={`${t(
									'product_details_store_accordion_shelf_text',
								)} ${selectedStoreStock.primaryLocation}`}
							/>
							<Text
								as="p"
								text={`${t(
									'product_details_store_accordion_art_no_text',
								)} ${id}`}
							/>
						</div>
					)}
					<div
						className={cn(
							'flex flex-wrap justify-between max-md:mt-6 max-md:items-center max-md:gap-2 print:mt-0 print:items-start',
							selectedStoreStock ? 'md:w-1/3' : 'md:w-1/2',
						)}
					>
						{onUpdateVariantQty && (
							<>
								<CartQuantity
									size="large"
									quantity={quantity}
									id={`quantity-${id}`}
									updateCart={onUpdateVariantQty}
									invalid={invalidQuantity}
									className="max-w-[10rem] md:max-w-[12rem] print:hidden"
								/>
								<Text as="p" className="mr-12 hidden print:block">
									{t('article_quantity_label')} {quantity}
								</Text>
							</>
						)}
						{!onUpdateVariantQty && (
							<Text as="p" className="mb-2">
								{t('article_quantity_label')} {quantity}
							</Text>
						)}
						<p
							className={cn(
								'font-bold',
								reducedPrice ? 'text-julaRed' : 'text-greyDarker',
							)}
						>
							{formatPriceText(rowSum?.displayValue, rowSum?.displaySymbol)}
						</p>
					</div>
				</div>
				<div className="mt-6 flex gap-4 print:hidden">
					{onRemoveVariant && (
						<Button
							variant="text"
							size="small"
							onClick={onRemoveVariant}
							data-cy={getTestDataAttrFrom(`remove-variant-${id}`)}
						>
							{removeVariantButtonText}
						</Button>
					)}
					{onMoveVariantToWishlist && (
						<Button
							variant="text"
							size="small"
							onClick={onMoveVariantToWishlist}
							data-cy={getTestDataAttrFrom(`move-variant-wishlist-${id}`)}
						>
							{moveVariantToWishlistButtonText}
						</Button>
					)}
					{onMoveVariantToCart && (
						<Button
							variant="text"
							size="small"
							onClick={onMoveVariantToCart}
							data-cy={getTestDataAttrFrom(`move-variant-cart-${id}`)}
						>
							{moveVariantToCartButtonText}
						</Button>
					)}
				</div>
				{volumePrice && (
					<Staffling volumePrice={volumePrice} className="mt-4" />
				)}
				{is.arrayWithLength(errors) && (
					<div className="mt-6">
						{errors.map((error) => (
							<InfoBox
								key={error.key}
								icon={error.level === 'Critical' ? 'error' : 'info'}
								variant={error.level === 'Critical' ? 'error' : 'information'}
								className="mt-2 text-sm"
							>
								{error.description}
							</InfoBox>
						))}
					</div>
				)}
			</div>
		</article>
	);
}
CartListItem.displayName = 'CartListItem';
