import type { Price as PriceModel, PriceTheme, PriceType } from 'models/price';
import { formatPriceText } from 'utils/format';
import { is } from 'utils/helpers';
import { i18n } from 'utils/i18n';

export type PriceProp = string | number | PriceModel;
export type LabelAlign = 'left' | 'right';

const CURRENCY_SYMBOL_REGEXP = /[,.:;]-/g;

export function hasReducedPrice(priceType: PriceType | undefined) {
	return ['Campaign', 'Clearance', 'JulaClub', 'JulaPro', 'Sale'].includes(
		priceType ?? '',
	);
}

/**
 * Remove any 'graphical' currency symbols like .- from a string.
 *
 * The frontend knows a bit too much here since the symbol always comes from
 * the backend, but it's necessary for screen readers since the symbol can
 * be read literally like 'period hyphen' in some cases.
 */
export function removeCurrencySymbols(text: string): string {
	// Maybe the component knows a bit too much here, but the bottom
	// tag is often used to display savings where the string contains
	// the price symbol (e.g. .-) that can be problematic for screen
	// readers. Just remove instead of replacing with a proper currency;
	// the context from the main price should make it clear that this
	// is related and having the full 'euros and cents' equivalent read
	// again is needlessly verbose.
	return text.replaceAll(CURRENCY_SYMBOL_REGEXP, '');
}

export function getTypeTheme(type: PriceType | undefined): PriceTheme {
	switch (type) {
		case 'Sale':
		case 'Clearance':
		case 'Campaign':
			return 'campaign';
		case 'JulaClub':
			return 'julaClub';
		case 'JulaPro':
			return 'julaPro';
		default:
			return 'regular';
	}
}

export function getTypeLabel(type: PriceType | undefined): string {
	switch (type) {
		case 'JulaClub':
			return 'JulaClub';
		case 'JulaPro':
			return 'JulaPro';
		default:
			return '';
	}
}

/**
 * Get the supplied component props and use price object properties as fallback
 * when available.
 */
export function getPropsFromPrice({
	currency,
	hideSavings,
	label,
	labelAlign,
	price,
	tagBottom,
	tagTop,
	theme,
	useExcVat,
}: {
	currency?: string;
	hideSavings: boolean;
	label?: string;
	labelAlign?: LabelAlign;
	price: PriceProp;
	tagBottom?: string;
	tagTop?: string;
	theme?: PriceTheme;
	useExcVat: boolean;
}) {
	const isPriceObj = is.object(price);
	const savings = isPriceObj
		? useExcVat
			? price.saveExcVat && price.saveExcVat.displayValue !== '0'
				? price.saveExcVat
				: undefined
			: price.saveIncVat && price.saveIncVat.displayValue !== '0'
				? price.saveIncVat
				: undefined
		: undefined;

	// @ts-expect-error TypeScript thinks the else branch sets `PriceProp` even
	// though it knows it's not `PriceModel`, which only leaves string | number.
	const priceNum: string | number = isPriceObj
		? useExcVat
			? (price.priceExcVat?.displayValue ?? '')
			: (price.priceIncVat?.displayValue ?? '')
		: price;

	return {
		priceNum,
		currency:
			currency ??
			(isPriceObj
				? useExcVat
					? (price.priceExcVat?.displaySymbol ?? '')
					: (price.priceIncVat?.displaySymbol ?? '')
				: ''),
		theme: theme ?? (isPriceObj ? getTypeTheme(price.priceType) : 'regular'),
		tagTop:
			tagTop ??
			(isPriceObj && price.fromPrice ? getTypeLabel(price.priceType) : ''),
		tagBottom:
			tagBottom ??
			(savings && !hideSavings
				? `${i18n.t('product_pricetag_save')} ${formatPriceText(savings.displayValue, savings.displaySymbol)}`
				: ''),
		label:
			label ??
			(isPriceObj
				? price.fromPrice
					? i18n.t('product_pricetag_from')
					: getTypeLabel(price.priceType)
				: ''),
		labelAlign:
			labelAlign ?? (isPriceObj && price.fromPrice ? 'left' : 'right'),
	};
}
