import React from 'react';

import Button from 'components/Button';
import GenericForm from 'components/GenericForm';
import { Step, Stepper } from 'components/Stepper';
import type { Section } from 'models/sitecore';
import type { Worksite } from 'state-machines/createJulaProCustomer';
import { formatTemplate } from 'utils/format';
import { is } from 'utils/helpers';

interface Props {
	resetToSelectOrganization: () => void;
	section: Section | undefined;
	selectCredit: (credit: number) => void;
	selectedCredit: number | undefined;
	selectedWorksite: Worksite | undefined;
}

function parsedCredit(credit: unknown) {
	if (is.number(credit)) {
		return credit;
	}
	if (is.string(credit)) {
		return Number.parseInt(credit, 10);
	}
	return 0;
}

export default function StepTwoContent({
	resetToSelectOrganization,
	section,
	selectCredit,
	selectedCredit,
	selectedWorksite,
}: Props) {
	if (!section) {
		return null;
	}

	const {
		backText,
		description,
		form,
		heading,
		submitText,
		submitTextVariation,
	} = section;

	const withCreditButtonText = submitText.value;
	const withoutCreditButtonText = submitTextVariation.value;
	return (
		<>
			<Stepper className="mx-8 mb-8 mt-4 flex items-center">
				<Step number={1} status="done" />
				<Step number={2} status="active" />
				<Step number={3} status="pending" />
			</Stepper>

			<p className="mb-1 font-bold">
				{heading?.value.includes('{{company}}')
					? formatTemplate(heading.value, {
							company: selectedWorksite?.companyName,
						})
					: `${heading?.value} ${selectedWorksite?.companyName}`}
			</p>
			<p className="mb-8">{description.value}</p>

			<GenericForm
				inputSpace="my-4"
				fields={form}
				initialValues={
					selectedCredit ? { creditLimit: selectedCredit } : undefined
				}
				onSubmitCallback={(formValues) => {
					selectCredit(parsedCredit(formValues.creditLimit));
				}}
				submitButtonText={(formValues) =>
					parsedCredit(formValues.creditLimit) > 0
						? withoutCreditButtonText
						: withCreditButtonText
				}
			/>

			<div className="mt-4 text-center">
				<Button variant="text" onClick={() => resetToSelectOrganization()}>
					{backText.value}
				</Button>
			</div>
		</>
	);
}
StepTwoContent.displayName = 'AccountJulaProSignupForm_StepTwoContent';
