import React, { useState } from 'react';

import { WebStockBalanceDetailed } from 'components/Stock';
import type { WebStock as WebStockModel } from 'models/api';
import { Product } from 'models/product';
import { getProductDetailsWebStockStatus } from 'utils/business-logic/stock';

import WatchableVariantPopover from './WatchableVariantPopover';

interface WebStockProps {
	isLoadingInitialStock: boolean;
	product: Product;
	webStock: WebStockModel | undefined;
}

export default function WebStock({
	isLoadingInitialStock,
	product,
	webStock,
}: WebStockProps) {
	const [watchablePopoverOpen, setWatchablePopoverOpen] = useState(false);
	const status = getProductDetailsWebStockStatus({
		isSellable: product.salesAttributes.isSellable,
		isSellableOnWeb: product.salesAttributes.isSellableOnWeb,
		isClickAndCollectOnly: product.salesAttributes.isClickAndCollectOnly,
		isWatchableIfOutOfStock: product.salesAttributes.isWatchableIfOutOfStock,
		reStockWithinAcceptableTimespan:
			webStock?.reStockWithinAcceptableTimespan ?? false,
		nextDeliveryInMonth: webStock?.nextDeliveryInMonth ?? '',
		availableStockLevel: webStock?.availableStockLevel ?? 0,
	});
	const isWatchable =
		status === 'ReStock-Watchable' || status === 'ReStock-Watchable-Delayed';
	return (
		<>
			<WebStockBalanceDetailed
				status={status}
				isLoading={isLoadingInitialStock}
				product={product}
				webStock={webStock}
				onWatchableVariantStockClick={() => {
					setWatchablePopoverOpen(true);
				}}
			/>
			{isWatchable && (
				<WatchableVariantPopover
					productId={product.id}
					isOpen={watchablePopoverOpen}
					onClose={() => {
						setWatchablePopoverOpen(false);
					}}
				/>
			)}
		</>
	);
}
WebStock.displayName = 'ProductDetails_StockInformation_WebStock';
