import React from 'react';

import Button from 'components/Button';
import Price, { BasePriceText, hasReducedPrice } from 'components/Price';
import Text from 'components/Text';
import {
	useGlobalMemberPopoversContext,
	usePriceContext,
	useUserContext,
} from 'contexts';
import type { Price as PriceModel } from 'models/price';
import { cn } from 'utils/classNames';
import { formatDate, formatPriceText } from 'utils/format';
import { useI18n } from 'utils/i18n';

interface Props {
	basePrice: PriceModel | undefined;
	displayPrice: PriceModel | undefined;
}

export default function Prices({ basePrice, displayPrice }: Props) {
	const { t } = useI18n();
	const { togglePriceExcVat, usePriceExcVat } = usePriceContext();
	const { openJulaClubLogin } = useGlobalMemberPopoversContext();
	const userInfo = useUserContext();

	if (!displayPrice) {
		return null;
	}
	const isReducedPrice = hasReducedPrice(displayPrice.priceType);

	return (
		<>
			<div>
				<Price price={displayPrice} size="large" />

				{!togglePriceExcVat && displayPrice?.priceExcVat && (
					<Text
						as="pSmall"
						className={cn(
							isReducedPrice && 'text-julaRed',
							displayPrice.priceType !== 'Base' && 'mt-2',
						)}
					>
						{t('product_details_price_vat_excluded_text', {
							priceExclVat: formatPriceText(
								displayPrice?.priceExcVat.displayValue,
								displayPrice?.priceExcVat.displaySymbol,
							),
						})}
					</Text>
				)}
				{togglePriceExcVat && (
					<div className="mt-2">
						<Button onClick={togglePriceExcVat} variant="text">
							{t(
								usePriceExcVat
									? 'price_vat_excluded_text'
									: 'price_vat_included_text',
							)}
						</Button>
					</div>
				)}
			</div>

			<div className="mt-6 empty:hidden">
				{displayPrice.maxPurchaseQuantity &&
					displayPrice.maxPurchaseQuantity > 0 &&
					isReducedPrice && (
						<Text as="pSmall" className="mb-2 font-bold text-julaRed">
							{t('product_details_maximum_purchase_quantity_text', {
								amount: displayPrice.maxPurchaseQuantity,
							})}
						</Text>
					)}
				{displayPrice.validTo && isReducedPrice && (
					<Text as="pSmall" className="text-julaRed">
						{t('product_details_valid_to_text', {
							validTo: formatDate(displayPrice.validTo),
						})}
					</Text>
				)}
				{displayPrice.comparisonPrice?.title &&
					displayPrice.comparisonPrice.price &&
					isReducedPrice && (
						<Text
							as="pSmall"
							text={displayPrice.comparisonPrice.title}
							className="text-julaRed"
						>
							{`${displayPrice.comparisonPrice.title} `}
							{formatPriceText(
								displayPrice.comparisonPrice.price,
								displayPrice.comparisonPrice.priceSymbol,
							)}
						</Text>
					)}

				{displayPrice.priceType !== 'Base' && basePrice && (
					<BasePriceText
						basePrice={basePrice}
						displayedPriceType={displayPrice.priceType}
						recentLowestPriceKey="product_details_recent_lowest_price_text"
						basePriceKey="product_details_base_price_text"
						className="text-sm font-bold"
					/>
				)}
				{basePrice?.comparisonPrice?.title &&
					basePrice.comparisonPrice.price && (
						<Text as="pSmall">
							{`${basePrice.comparisonPrice.title} `}
							{formatPriceText(
								basePrice.comparisonPrice.price,
								basePrice.comparisonPrice.priceSymbol,
							)}
						</Text>
					)}
				{userInfo?.customerType === 'Anonymous' &&
					displayPrice.priceType === 'JulaClub' && (
						<Button
							size="small"
							variant="text"
							onClick={() => openJulaClubLogin()}
						>
							{t('product_details_jula_club_member_nudge_text')}
						</Button>
					)}
			</div>
		</>
	);
}
Prices.displayName = 'Prices';
