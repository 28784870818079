import React, { useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';

import ActionButton, { ActionButtonState } from 'components/ActionButton';
import { RadioGroup } from 'components/FormUi';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import { SummaryProductLine } from 'components/ProductLine';
import ProgressSteps from 'components/ProgressSteps';
import RichText from 'components/RichText';
import Text from 'components/Text';
import { useOrderStatus, useSendOrderStatusCustomerResponse } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { getProductImageSrc } from 'utils/business-logic';
import { formatDate, formatDateAndTime } from 'utils/format';
import { ignorePromiseRejection, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { parseMarkdown } from 'utils/string';

export type Props = JulaComponentProps & {
	fields?: {
		buttonText?: Field<string>;
		choice1?: Field<string>;
		choice2?: Field<string>;
		choiceHeading?: Field<string>;
		description?: Field<string>;
		title?: Field<string>;
		yellowBox?: Field<string>;
	};
};

export default function AccountOrderStatus({ fields }: Props) {
	const { t } = useI18n();
	const router = useRouter();
	const { query } = router;
	const customerId = query?.c as string;
	const orderId = query?.id as string;
	const CHOICE1 = 'choice1';
	const CHOICE2 = 'choice2';

	const [selectedChoice, setSelectedChoice] = useState(CHOICE1);
	const { error, isLoading, orderStatus } = useOrderStatus(customerId, orderId);
	const {
		error: customerResponseError,
		isMutating,
		trigger,
	} = useSendOrderStatusCustomerResponse(customerId, orderId);

	const hasErrors = is.arrayWithLength(
		customerResponseError?.businessLogicErrors,
	);
	const actionButtonState: ActionButtonState = isMutating
		? 'loading'
		: hasErrors
			? 'failure'
			: 'idle';

	const loadedAndOk = !isLoading && !error;

	const { lines, transactionHead } = orderStatus ?? {};
	const {
		dateOfPurchase,
		deliveryMethod,
		orderNumber,
		pickupLocation,
		status,
		statusProgress = [],
		trackingLink,
	} = transactionHead ?? {};

	const {
		buttonText,
		choice1,
		choice2,
		choiceHeading,
		description,
		title,
		yellowBox,
	} = fields ?? {};

	const indexOfFirstUncompleted = statusProgress?.findIndex(
		(s) => s.completed === false,
	);
	const statusType = status?.type ?? 'Unknown';
	const statusText = indexOfFirstUncompleted
		? statusProgress[indexOfFirstUncompleted - 1]?.text
		: statusProgress.pop()?.text;

	const mdText = yellowBox?.value.replace(
		'{{date}}',
		formatDateAndTime(status?.customerActionExpiration),
	);
	const infoBoxText = parseMarkdown(mdText, { inline: true });

	return (
		<LayoutContainer>
			{error && (
				<>
					<Text
						as="h1"
						className="mb-2"
						text={t('order_status_error_heading')}
					/>
					<Text
						as="p"
						className="mb-2"
						text={t('order_status_error_message')}
					/>
				</>
			)}
			{isLoading && <LoadingSpinner className="m-auto" />}
			{loadedAndOk && (
				<>
					<Text as="p" className="mb-2 mt-4">
						{t('order_status_order_number', { number: orderNumber })}
					</Text>
					<Text as="p" className="mb-2">
						{t('order_status_order_date', { date: formatDate(dateOfPurchase) })}
					</Text>
					<Text as="h2" className="mb-2 mt-14">
						{t('order_status_heading')}
					</Text>
					<Text as="p" className="font-bold">
						{statusText}
					</Text>
					{statusType === 'AwaitingCustomerResponse' && (
						<>
							<Text as="h3" text={title?.value} className="mb-4 mt-10" />
							<div className="rounded-lg border px-4 py-6">
								<RichText html={description?.value} />
								<InfoBox variant="information" icon="info" className="mb-8">
									<RichText html={infoBoxText} />
								</InfoBox>
								<div className="mb-6">
									<RadioGroup
										id="customerResponse"
										onChange={(e) => setSelectedChoice(e.target.value)}
										name="customerResponse"
										legend={choiceHeading?.value || ''}
										options={[
											{
												id: `id-${CHOICE1}`,
												label: choice1?.value || '',
												value: CHOICE1,
											},
											{
												id: `id-${CHOICE2}`,
												label: choice2?.value || '',
												value: CHOICE2,
											},
										]}
										value={selectedChoice}
									/>
								</div>
								{hasErrors && (
									<InfoBox icon="error" variant="error" className="mb-2">
										{t('order_status_failed_to_make_a_choice')}
									</InfoBox>
								)}
								<ActionButton
									customState={actionButtonState}
									variant="cta"
									displayWidth="full"
									size="xl"
									onClick={() =>
										ignorePromiseRejection(
											trigger({ cancel: selectedChoice === CHOICE2 }),
										)
									}
								>
									{buttonText?.value}
								</ActionButton>
							</div>
						</>
					)}
					{statusType !== 'AwaitingCustomerResponse' && (
						<>
							{trackingLink && (
								<a
									href={trackingLink}
									className="underline hover:no-underline"
									target="_blank"
									rel="noopener noreferrer"
								>
									{t('purchase_track_package_button_text')}
								</a>
							)}
							{is.arrayWithLength(statusProgress) && (
								<div className="mt-8">
									<Text as="h3" className="mb-2">
										{t('order_status_progress_heading')}
									</Text>
									<ProgressSteps steps={statusProgress} />
								</div>
							)}
							{status?.type !== 'Cancelled' &&
								(deliveryMethod || pickupLocation) && (
									<div className="mt-12">
										<Text as="h2" className="mb-2">
											{t('order_status_pickup_location')}
										</Text>
										{deliveryMethod && (
											<Text as="p" className="mb-2">
												{t('order_status_delivery_method', {
													deliveryMethod,
												})}
											</Text>
										)}
										{pickupLocation && (
											<Text as="p" className="mb-2" text={pickupLocation} />
										)}
									</div>
								)}
							{is.arrayWithLength(lines) && (
								<div className="mt-14">
									<Text as="h2" className="mb-2">
										{t('order_status_products')}
									</Text>
									<ul>
										{lines.map((line) => (
											<SummaryProductLine
												link={line?.url}
												amount={line?.quantity}
												heading={line.title}
												imageSrc={getProductImageSrc(
													line?.mainImage?.formats,
													true,
												)}
												discount={false}
												key={line.variantId}
											/>
										))}
									</ul>
								</div>
							)}
						</>
					)}
				</>
			)}
		</LayoutContainer>
	);
}
AccountOrderStatus.displayName = 'AccountOrderStatus';
