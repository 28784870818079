import { stringify } from 'query-string';

/**
 * Create a URL string from a base URL and an object of query parameters.
 *
 * Any keys that have null, undefined or empty string will be skipped.
 *
 * @example
 *
 * createUrl('/base?existing=param', {
 *   nope: null,
 *   empty: '',
 *   zero: 0,
 *   arr: [1, 2],
 * });
 * // => '/base?existing=param&zero=0&arr=1&arr=2'
 */
export function createUrl(
	baseUrl: string,
	params: Record<
		string,
		string | number | (string | number)[] | undefined | null
	>,
	options: { replaceParams?: boolean; sortParams?: boolean } = {},
) {
	let url = baseUrl;
	if (options.replaceParams) {
		const baseParts = baseUrl.split('?');
		const baseQuery = new URLSearchParams(baseParts[1]);
		Object.keys(params).forEach((key) => {
			baseQuery.delete(key);
		});
		url = [baseParts[0], baseQuery.toString()].filter(Boolean).join('?');
	}
	const qs = stringify(params, {
		skipEmptyString: true,
		skipNull: true,
		sort: options.sortParams === false ? false : undefined,
	});
	if (!qs) {
		return baseUrl;
	}
	return url.includes('?') ? `${url}&${qs}` : `${url}?${qs}`;
}

/**
 * Get the specified query parameter from a URL.
 *
 * @param url - Can be absolute, root relative or just the query params part,
 *   as long as it contains a `?`.
 * @param param - Query parameter name.
 * @returns Empty string if the param isn't found.
 * @example
 *
 * const someUrl = '/the-url?key=val';
 * getQueryParam(someUrl, 'key');
 * // => 'val'
 */
export function getQueryParam(url: string, param: string): string {
	const params = url.includes('?') ? url.split('?').pop() || '' : '';
	return new URLSearchParams(params).get(param) || '';
}

/**
 * Get the last segment of a URL/file path.
 *
 * @example
 *
 * getLastPathPart('/my/path/things/')
 * // => 'things'
 *
 * getLastPathPart('/my/path/things?query=param')
 * // => 'things'
 */
export function getLastPathSegment(path: string): string {
	return path.split('?')[0].split('/').filter(Boolean).pop() ?? '';
}

/**
 * Remove all leading slashes from a string.
 */
export function removeLeadingSlash(str: string): string {
	return str.replace(/^\/+/, '');
}

/**
 * Remove all trailing slashes from a string.
 */
export function removeTrailingSlash(str: string): string {
	return str.replace(/\/+$/, '');
}

/**
 * Check if two paths are the same.
 */
export function isSamePath(path1: string, path2: string): boolean {
	return (
		removeTrailingSlash(path1).toLowerCase() ===
		removeTrailingSlash(path2).toLowerCase()
	);
}

/**
 * Join path segments with slash, removing any duplicate slashes.
 */
export function joinPath(...parts: string[]): string {
	return parts.join('/').replaceAll(/\/+/g, '/');
}

/**
 * Check if a URL path starts with the specified prefix. If a URL isn't supplied
 * it defaults to the current location.
 *
 * Matches distinct path segments so when checking '/catalog/product' only
 * 'catalog' or 'catalog/product' will match, not 'cat' or similar partial
 * strings.
 */
export function pathStartsWith(pathPrefix: string, url?: string): boolean {
	let path = '';
	if (url) {
		// The URL constructor throws on relative URLs, assume any exceptions are
		// because of that.
		try {
			const urlObj = new URL(url);
			path = urlObj.pathname;
		} catch {
			path = url;
		}
	} else {
		path = globalThis.location?.pathname ?? '';
	}
	// Handle with and without leading slash.
	return new RegExp(
		`^/?${removeLeadingSlash(pathPrefix.toLowerCase())}\\b`,
	).test(path.toLowerCase());
}
