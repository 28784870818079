import React, { useState } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSelector } from '@xstate/react';

import Button from 'components/Button';
import GdprAccordion from 'components/GdprAccordion';
import GenericForm from 'components/GenericForm';
import Img from 'components/Img';
import InfoBox, { ErrorMessageInfoBox } from 'components/InfoBox';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useGlobalMachinesContext } from 'contexts';
import { useContactInformation, useCustomerInformation } from 'hooks';
import type {
	JulaComponentProps,
	PartialPopoverProps,
} from 'lib/component-props';
import type { BusinessLogicError } from 'models/api';
import { StoredFileResponse } from 'models/api/userFileStorage';
import type { FileField, FormFields } from 'models/sitecore';
import {
	selectCustomerContactId,
	selectCustomerType,
	selectIsAuthenticatedUser,
} from 'state-machines/authentication';
import { API_URL, fetchResult } from 'utils/fetch';
import { getFieldErrors } from 'utils/formHelpers';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		description: Field<string>;
		form: FormFields;
		heading: Field<string>;
		name: Field<string>;
		submitText: Field<string>;
	};
	partialProps?: PartialPopoverProps;
};

export default function ContactForm({ fields, partialProps }: Props) {
	const { userService } = useGlobalMachinesContext();
	const customerType = useSelector(userService, selectCustomerType);
	const customerContactId = useSelector(userService, selectCustomerContactId);
	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);

	const {
		customerInformation,
		error: errorCustomerInformation,
		isLoading: isLoadingCustomerInformation,
	} = useCustomerInformation(isAuthenticatedUser);

	const isPro = customerType === 'Pro';
	const {
		contactInformation,
		error: errorContactInformation,
		isLoading: isLoadingContactInformation,
	} = useContactInformation(customerContactId, isPro);

	const mobile = isPro
		? contactInformation?.phoneNumber.e164
		: customerInformation?.cellPhoneNumberFormatted?.e164;
	const email = isPro
		? contactInformation?.email
		: customerInformation?.emailAddress;

	const hasError = errorCustomerInformation || errorContactInformation;
	const isLoading =
		(isAuthenticatedUser && !isPro && isLoadingCustomerInformation) ||
		(isAuthenticatedUser && isPro && isLoadingContactInformation);

	const [businessLogicErrors, setBusinessLogicErrors] = useState<
		BusinessLogicError[] | undefined
	>(undefined);
	const [submitSuccess, setSubmitSuccess] = useState(false);
	const { t } = useI18n();

	if (!fields) {
		return null;
	}
	const { description, form, heading, submitText } = fields;

	const onSubmit = async (genericFormData: Record<string, unknown>) => {
		const formData = { ...genericFormData };

		const selectFields = form.filter((field) => field.type === 'SelectField');
		const fileField = form.find(
			(field): field is FileField => field.type === 'FileField',
		);

		selectFields.forEach((selectField) => {
			if (selectField.sendLabel) {
				const categoryId = formData[selectField.name];
				const selectedOption = selectField.options.find(
					(option) => option.value === categoryId,
				);
				formData[selectField.labelName] = selectedOption?.label;
			}
		});

		const attachments = (
			formData[`${fileField?.name}-data`] as StoredFileResponse[]
		)?.map((attachmentData) => attachmentData.fileName);

		if (attachments?.length > 0) {
			delete formData[`${fileField?.name}-data`];
			formData.attachments = attachments;
		}

		const response = await fetchResult(`${API_URL}CustomerService/postForm`, {
			method: 'POST',
			body: JSON.stringify(formData),
		});

		if (response.isSuccess()) {
			setSubmitSuccess(true);
			return undefined;
		}

		if (
			response.isFailure('ValidationError') &&
			is.arrayWithLength(response.error.generalErrors)
		) {
			setBusinessLogicErrors(response.error.generalErrors);
		}
		return getFieldErrors(response.error);
	};

	if (hasError) {
		return (
			<InfoBox
				icon="error"
				variant="error"
				message={t('general_critical_error_text')}
			/>
		);
	}

	return (
		<>
			{!submitSuccess && (
				<>
					{is.arrayWithLength(businessLogicErrors) && (
						<ErrorMessageInfoBox
							errors={businessLogicErrors}
							className="sticky top-0 z-99 mb-4 text-sm"
						/>
					)}
					<Text className="mb-2" as="h2" field={heading} />
					<Text className="mb-2" as="p" field={description} />
					<GdprAccordion />
					{isLoading && (
						<Skeleton>
							<SkeletonItem height="5rem" className="mt-4" />
							<SkeletonItem height="10rem" className="mt-4" />
							<SkeletonItem height="20rem" className="mt-4" />
						</Skeleton>
					)}
					{!isLoading && (
						<>
							{isAuthenticatedUser && (
								<>
									<Text className="mb-4 font-bold" as="p">
										{t('contact_form_details_heading')}
									</Text>

									<InfoBox
										icon="info"
										variant="information"
										className="mb-4"
										message={t('contact_form_details_info_text')}
									/>
									<Text
										as="p"
										text={`${t(isPro ? 'contact_form_details_company_name_label' : 'contact_form_details_name_label')}: ${customerInformation?.fullName}`}
									/>
									<Text
										as="p"
										text={`${t(
											'contact_form_details_customer_number_label',
										)}: ${customerInformation?.id}`}
									/>
									{isPro && (
										<Text
											as="p"
											text={`${t(
												'contact_form_details_contact_label',
											)}: ${contactInformation?.name}`}
										/>
									)}
								</>
							)}
							<GenericForm
								inputSpace="mt-4"
								fields={form}
								initialValues={{ email, mobile }}
								className="mt-6"
								fileUploadUrl={`${API_URL}UserFileStorage/ContactCustomerService`}
								onSubmitCallback={onSubmit}
								resetForm
								saveStateFor="ContactForm"
								submitButtonText={submitText.value}
								submitButtonClassName="mt-6"
							/>
						</>
					)}
				</>
			)}
			{submitSuccess && (
				<>
					<Img
						width={80}
						height={80}
						src="/assets/images/graphic-signin-mail.svg"
						className="mx-auto mt-8 block size-20 object-cover"
					/>
					<Text as="h2" className="mt-8">
						{t('contact_form_thank_you_heading')}
					</Text>

					<Text as="p" className="mt-6">
						{t('contact_form_feedback_interval_text')}
					</Text>

					<div className="mt-8">
						<Button
							variant="primary"
							displayWidth="full"
							className="my-4"
							onClick={() => partialProps?.onPopoverClose()}
						>
							{t('contact_form_ok_button')}
						</Button>
					</div>
				</>
			)}
		</>
	);
}
ContactForm.displayName = 'ContactForm';
