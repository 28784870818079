import React, {
	type KeyboardEvent,
	type KeyboardEventHandler,
	type RefObject,
	useRef,
} from 'react';

import Link from 'components/Link';
import PopularSearchTerms from 'components/PopularSearchTerms';
import Text from 'components/Text';
import type { MainLinkFields } from 'models/sitecore';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

import {
	getLinkListId,
	GRID_ROWS,
	SCROLL_LOCK_WHITELIST_CLASS,
} from './helpers';
import PageHeaderLinkListItem, {
	type DropdownLinkClickHandler,
	type LinkListItemTabHandler,
} from './PageHeaderLinkListItem';

export type LinkListTabHandler = (
	level: number,
	event: KeyboardEvent<HTMLAnchorElement>,
) => void;

interface Props {
	baseId: string;
	className?: string;
	firstLinkRef?: RefObject<HTMLAnchorElement>;
	hasPopularContent?: boolean;
	isLargeScreen: boolean | undefined;
	level: number;
	linkBarColor?: string;
	links?: MainLinkFields[];
	onFirstLinkTab?: LinkListTabHandler;
	onLastLinkTab?: LinkListTabHandler;
	onLinkClick?: DropdownLinkClickHandler;
	onSelectedLinkTab?: LinkListTabHandler;
	openLevels: number;
	parentId?: string;
	renderPopularSearchTerms?: boolean;
	selectedLink?: MainLinkFields;
	selectedLinkRef?: RefObject<HTMLAnchorElement>;
	selectedSiblingLinkRef?: RefObject<HTMLAnchorElement>;
	title?: string;
	viewAllHref?: string;
}

/** A single level of links in the dropdown tree */
const PageHeaderLinkList = React.forwardRef<HTMLDivElement, Props>(
	(
		{
			baseId,
			className,
			firstLinkRef,
			hasPopularContent,
			isLargeScreen,
			level,
			linkBarColor,
			links,
			onFirstLinkTab,
			onLastLinkTab,
			onLinkClick,
			onSelectedLinkTab,
			openLevels,
			parentId,
			renderPopularSearchTerms = false,
			selectedLink,
			selectedLinkRef,
			selectedSiblingLinkRef,
			title,
			viewAllHref,
		},
		ref,
	) => {
		const { t } = useI18n();
		const lastPopularSearchTermRef = useRef<HTMLAnchorElement>(null);
		const linkCount = links?.length ?? 0;
		const selectedLinkIndex = links
			? links.findIndex((link) => link.id === selectedLink?.id)
			: -1;

		const handleLinkTab: LinkListItemTabHandler = (linkId, linkLevel, e) => {
			const linkIndex = links
				? links?.findIndex((link) => link.id === linkId)
				: -1;
			const isSelectedLink = linkId === selectedLink?.id;
			if (isSelectedLink && onSelectedLinkTab) {
				onSelectedLinkTab(linkLevel, e);
			}
			if (linkIndex === 0 && onFirstLinkTab) {
				onFirstLinkTab(linkLevel, e);
			}
			if (
				// Has no view all link
				!viewAllHref &&
				// Has no popular search terms
				!lastPopularSearchTermRef.current &&
				// Give the selected handler priority
				!isSelectedLink &&
				linkIndex === linkCount - 1 &&
				onLastLinkTab
			) {
				onLastLinkTab(linkLevel, e);
			}
		};

		const handleViewAllKeydown: KeyboardEventHandler<HTMLAnchorElement> = (
			e,
		) => {
			if (
				e.key === 'Tab' &&
				!e.shiftKey &&
				// Only relevant when there are no popular search terms.
				!lastPopularSearchTermRef.current &&
				onLastLinkTab &&
				!hasPopularContent
			) {
				onLastLinkTab(level, e);
			}
		};

		const handleLastSearchTermTab: KeyboardEventHandler<HTMLAnchorElement> = (
			e,
		) => {
			if (onLastLinkTab) {
				onLastLinkTab(level, e);
			}
		};

		const hasViewAll = level > 1 && viewAllHref;
		const hasColumns = linkCount >= 12 && level >= 3;

		// Keep absolute minimum rows at 8 but prefer filling the first column
		// for some counts over keeping it as even as possible (e.g. 9 + 5 rather
		// than 8 + 6).
		const minRows = Math.max(Math.min(linkCount - 5, 10), 8);
		// Count + 1 to account for the 'view all' link that's added outside
		// the looped list of links.
		const gridColsClass = hasColumns
			? GRID_ROWS[Math.max(Math.ceil((linkCount + 1) / 2), minRows)]
			: '';

		const isOpen = isLargeScreen
			? level === 1
				? true
				: openLevels >= level
			: level === openLevels;

		return (
			<div
				ref={ref}
				id={`${baseId}-${level}`}
				// Escape hatch for focus management.
				data-open={String(isOpen)}
				className={cn(
					className,
					SCROLL_LOCK_WHITELIST_CLASS,
					'z-20 bg-white pb-7',
					!isOpen && 'invisible',
					!isLargeScreen && [
						'absolute bottom-0 top-0 w-full overscroll-contain transition-fadeTransform',
						!isOpen && 'delay-closedToggledMenuPanel',
						level === openLevels && 'translate-x-0 overflow-y-auto',
						level > openLevels && 'translate-x-5 opacity-0',
						level < openLevels && '-translate-x-5',
					],
					isLargeScreen && [
						level === 1 && 'md:bg-greyLighter md:forced-colors:border-r',
						// Divider line via a pseudo element.
						level === 3 && [
							'relative',
							'before:absolute',
							'before:top-0',
							'before:-left-3',
							'before:block',
							'before:h-full',
							'before:border-r',
							'before:border-r-greyLight',
						],
					],
				)}
			>
				{title && (
					<Text
						as={(['h2', 'h3', 'h4'] as const)[level - 1] ?? 'h2'}
						styleAs={level > 1 ? 'h3' : undefined}
						className="mt-8 min-h-9 px-8 md:mt-10 md:min-h-12 md:px-6"
					>
						{title}
					</Text>
				)}
				{links && (
					<ul
						id={getLinkListId(baseId, parentId)}
						className={
							cn(
								!title && 'pt-7',
								hasColumns &&
									`grid-flow-col grid-cols-2 md:grid ${gridColsClass}`,
							) || undefined
						}
					>
						{links.map((link, i) => (
							<li
								key={link.id}
								aria-owns={
									link.id === selectedLink?.id
										? getLinkListId(baseId, selectedLink.id)
										: undefined
								}
							>
								<PageHeaderLinkListItem
									baseId={baseId}
									isSelected={link.id === selectedLink?.id}
									level={level}
									link={link}
									linkBarColor={linkBarColor}
									onClick={onLinkClick}
									onTab={handleLinkTab}
									ref={
										link.id === selectedLink?.id
											? selectedLinkRef
											: selectedLinkIndex !== -1 && i === selectedLinkIndex + 1
												? selectedSiblingLinkRef
												: i === 0
													? firstLinkRef
													: undefined
									}
								/>
							</li>
						))}
						{hasViewAll && (
							<li>
								<Link
									href={viewAllHref}
									ref={
										selectedLinkIndex === linkCount - 1
											? selectedSiblingLinkRef
											: undefined
									}
									className="flex min-h-[3rem] items-center px-8 text-base underline hover:no-underline md:min-h-[2.5rem] md:px-6"
									onKeyDown={handleViewAllKeydown}
								>
									{t('navigation_category_show_all_button', {
										category: title,
									})}
								</Link>
							</li>
						)}
					</ul>
				)}
				{renderPopularSearchTerms && (
					<PopularSearchTerms
						heading={t('search_popular_search_terms_text')}
						variant="chips"
						itemLimit={5}
						lastLinkRef={lastPopularSearchTermRef}
						onLastLinkTab={handleLastSearchTermTab}
						className="p-8"
					/>
				)}
			</div>
		);
	},
);
PageHeaderLinkList.displayName = 'PageHeaderLinkList';

export default PageHeaderLinkList;
