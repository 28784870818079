/**
 * Step
 */

import React from 'react';

import Icon from 'components/Icon';
import Text from 'components/Text';
import { cn } from 'utils/classNames';

interface Props {
	/** React children */
	children?: React.ReactNode;

	/** Pass optional classnames for the html */
	className?: string;

	/** The label under the circle of each step */
	label?: string;

	/** The number in the circle */
	number?: number;

	/** The status of the steps in the stepper */
	status: 'done' | 'active' | 'pending' | 'highlight';
}

/** A single step in a stepper component */
export default function Step({
	children,
	className,
	label,
	number,
	status,
}: Props) {
	return (
		<div
			className={cn('relative flex flex-col items-center', label && 'w-[6%]')}
		>
			<div
				className={cn(
					className,
					'mb-1 flex h-10 w-10 items-center justify-center rounded-full border-2 border-white',
					status === 'done' || status === 'active' || status === 'highlight'
						? 'bg-julaRed text-white'
						: 'bg-greyLight',
					status === 'active' ? 'text-white' : 'text-grey',
				)}
			>
				{status !== 'done' && (
					<p className={cn(className, 'font-bold')}>{children || number}</p>
				)}
				{status === 'done' && <Icon name="check" className={className} />}
			</div>
			<Text
				as="pSmall"
				className={cn(
					className,
					'm-0',
					'w-max',
					'text-center',
					'max-w-[7rem]',
					status === 'active' ? 'font-bold' : '',
				)}
			>
				{label}
			</Text>
		</div>
	);
}
Step.displayName = 'Step';
