import { useCallback, useEffect, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

import type { ValidationError } from 'errors';
import { useFileUpload, useValueChangeEffect } from 'hooks';
import {
	CreateCustomizationRequest,
	CustomizationPlacement,
	ProductCustomizationResponse,
} from 'models/api/ProductCustomization';
import { StoredFileResponse } from 'models/api/userFileStorage';
import type { Price } from 'models/price';
import { ActionButtonState } from 'state-machines/actionButton.machine';
import { API_URL, fetchData, fetchResult } from 'utils/fetch';
import { ignorePromiseRejection, is } from 'utils/helpers';
import { createUrl } from 'utils/url';

const fileStorageApiUrl = `${API_URL}UserFileStorage/ProductCustomization`;

export interface ExistingPlacement extends CustomizationPlacement {
	customizationId: string;
}

function mapCreateCustomizationRequestItems(
	printPlacements: CustomizationPlacement[] | undefined,
): CreateCustomizationRequest[] {
	return (
		printPlacements?.map((printPlacement) => ({
			placementId: printPlacement.id,
			optionKey: printPlacement.option?.key,
			printImageFilename: printPlacement.printImageFilename?.fileName,
		})) ?? []
	);
}

export default function useProductPrint({
	id,
	isActive,
}: {
	id: string;
	isActive: boolean;
}) {
	const [print, setPrint] = useState<StoredFileResponse | undefined>(undefined);
	const [totalCost, setTotalCost] = useState<Price | undefined>(undefined);
	const [currentPrintPlacements, setCurrentPrintPlacements] = useState<
		CustomizationPlacement[] | undefined
	>();
	const [currentCustomizationId, setCurrentCustomizationId] = useState<
		string | undefined
	>();

	const [addPrintPlacementIsLoading, setAddPrintPlacementIsLoading] =
		useState(false);
	const [addPrintPlacementErrors, setAddPrintPlacementErrors] = useState<
		ValidationError | undefined
	>();
	const addPrintPlacementButtonState: ActionButtonState =
		addPrintPlacementIsLoading
			? 'loading'
			: addPrintPlacementErrors
				? 'failure'
				: 'success';

	const printCustomizationApiUrl = `${API_URL}ProductCustomization/${id}`;

	// Get print customization for product with possible existing print placements
	const {
		data: productCustomization,
		error: productCustomizationError,
		isLoading: isLoadingProductCustomization,
	} = useSWRImmutable(
		isActive
			? createUrl(printCustomizationApiUrl, {
					showHistory: 'true',
				})
			: null,
		fetchData<ProductCustomizationResponse>,
	);

	const [isInitialLoading, setIsInitialLoading] = useState(isActive);

	useValueChangeEffect(isLoadingProductCustomization, () => {
		setIsInitialLoading(isLoadingProductCustomization);
	});

	// Get possible stored prints
	const { data: storedPrints, mutate: mutateStoredPrints } = useSWRImmutable(
		isActive ? fileStorageApiUrl : null,
		fetchData<StoredFileResponse[]>,
	);

	// Print upload
	const {
		errors: fileUploadErrors,
		hasError: hasFileUploadErrors,
		isLoading: fileUploadIsLoading,
		uploadedFiles,
		uploadFile,
	} = useFileUpload({
		url: fileStorageApiUrl,
	});

	useEffect(() => {
		if (uploadedFiles.length > 0) {
			setPrint(uploadedFiles.at(0));
			ignorePromiseRejection(mutateStoredPrints());
		}
	}, [mutateStoredPrints, uploadedFiles]);

	const reusePrint = useCallback(
		(printId: string | undefined) => {
			const existingPrint = storedPrints?.find(
				(file) => file.fileName === printId,
			);
			if (existingPrint) {
				setPrint(existingPrint);
			}
		},
		[storedPrints],
	);

	const removePrint = useCallback(() => {
		setPrint(undefined);
	}, []);

	const requestPrintPlacementUpdate = useCallback(
		async (printPlacements: CreateCustomizationRequest[]) => {
			setAddPrintPlacementIsLoading(true);
			const res = await fetchResult<ProductCustomizationResponse>(
				printCustomizationApiUrl,
				{ method: 'POST', body: JSON.stringify(printPlacements) },
			);
			setAddPrintPlacementIsLoading(false);
			if (res.isFailure('ValidationError')) {
				setAddPrintPlacementErrors(res.error);
				return;
			}
			if (res.isSuccess()) {
				const { customization } = res.value ?? {};
				setCurrentPrintPlacements(customization?.placements);
				setTotalCost(customization?.price);
				setCurrentCustomizationId(customization?.id);
			}
		},
		[printCustomizationApiUrl],
	);

	const addPrintPlacement = useCallback(
		(printPlacement: CreateCustomizationRequest) => {
			ignorePromiseRejection(
				requestPrintPlacementUpdate([
					...mapCreateCustomizationRequestItems(currentPrintPlacements),
					printPlacement,
				]),
			);
		},
		[currentPrintPlacements, requestPrintPlacementUpdate],
	);

	const removePrintPlacement = useCallback(
		(placementId: string) => {
			ignorePromiseRejection(
				requestPrintPlacementUpdate(
					mapCreateCustomizationRequestItems(
						currentPrintPlacements?.filter(
							(placement) => placement.id !== placementId,
						),
					),
				),
			);
		},
		[currentPrintPlacements, requestPrintPlacementUpdate],
	);

	return {
		// it is still not complete clear if swr caches 204 responses
		// if it does there could be cases where this leads to a forever loading state
		// since the initial loading state is never updated because the request is not made and no loading state change
		// is triggered, and since the empty response is cached there is never any `productCustomization` data
		isInitialLoading:
			isInitialLoading && !productCustomization && !productCustomizationError,
		hasError: Boolean(productCustomizationError),
		uploadPrint: uploadFile,
		print,
		storedPrints,
		customizationId: currentCustomizationId,
		existingPrintPlacements: productCustomization?.customizationHistory
			?.flatMap((customization) =>
				customization.placements?.map((placement) => ({
					...placement,
					customizationId: customization.id,
				})),
			)
			.filter(is.truthy) satisfies ExistingPlacement[] | undefined,
		addPrintPlacementButtonState,
		addPrintPlacementErrors,
		printUploadButtonState: (fileUploadIsLoading
			? 'loading'
			: hasFileUploadErrors
				? 'failure'
				: 'success') as ActionButtonState,
		printUploadErrors: fileUploadErrors,
		addPrintPlacement,
		removePrintPlacement,
		reusePrint,
		removePrint,
		placements:
			productCustomization?.variantCustomization.printOnClothes.placements,
		printPlacements: currentPrintPlacements,
		totalCost,
	};
}
