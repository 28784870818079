import React, { useState } from 'react';

import Accordion from 'components/Accordion';
import AddedProductPopover from 'components/AddedProductPopover';
import ErrorBoundary from 'components/ErrorBoundary';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import Reviews from 'components/Reviews';
import TechnicalSpecification from 'components/TechnicalSpecification';
import Text from 'components/Text';
import type { StoreIdName } from 'contexts';
import type { ValidationError } from 'errors';
import type {
	ExistingPlacement,
	ReviewImage,
	ReviewImageId,
} from 'hooks/product-details';
import type {
	ItemStockResponse,
	Question,
	QuestionSummary,
	Review,
	Stock,
} from 'models/api';
import type {
	CreateCustomizationRequest,
	CustomizationPlacement,
	Placement,
} from 'models/api/ProductCustomization';
import type { StoredFileResponse } from 'models/api/userFileStorage';
import type { Price } from 'models/price';
import type { CampaignResponse, Product } from 'models/product';
import type { ProductCard, RelatedProductCard } from 'models/productCard';
import type { ActionButtonState } from 'state-machines/actionButton.machine';
import type { ButtonStateWithId } from 'state-machines/cart';
import { getVerticalEnergyLabel } from 'utils/business-logic';
import { cn } from 'utils/classNames';
import type { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';
import { is, sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import {
	PRODUCT_ACCESSORIES_ID,
	PRODUCT_BELONGS_TO_ID,
	PRODUCT_IMPORTANT_INFO_ID,
	PRODUCT_REVIEWS_ID,
	PRODUCT_SPARE_PARTS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from './helpers/constants';
import {
	filterAdditionalTexts,
	getDocuments,
	getExternalTestReviewLogo,
} from './helpers/productDataHelpers';
import type { ProductAndSelectedImagesTabId } from './ProductDetailsImage/ProductAndSelectedImagesPopover';
import ExpiredProductInfo from './ExpiredProductInfo';
import ProductDetailsBrandInfo from './ProductDetailsBrandInfo';
import { ProductDetailsCampaignPackages } from './ProductDetailsCampaign';
import ProductDetailsDescription from './ProductDetailsDescription';
import ProductDetailsExternalReview from './ProductDetailsExternalReview';
import ProductDetailsImage from './ProductDetailsImage';
import ProductDetailsImportantInfo from './ProductDetailsImportantInfo';
import ProductDetailsIntro from './ProductDetailsIntro';
import ProductDetailsSidebar from './ProductDetailsSidebar';
import ProductDetailsSimilarProducts from './ProductDetailsSimilarProducts';
import ProductQuestions from './ProductQuestions';
import RelatedProductsAccordion from './RelatedProductsAccordion';

export interface Props {
	accessoriesHasNextPage: boolean;
	accessoriesIsLoading: boolean;
	accessoriesIsLoadingMore: boolean;
	accessoriesProducts: RelatedProductCard[] | undefined;
	addPrintPlacementButtonState: ActionButtonState;
	addPrintPlacementErrors: ValidationError | undefined;
	allReviewImages: ReviewImage[] | undefined;
	allStoresStock: Stock[] | undefined;
	belongsToHasNextPage: boolean;
	belongsToIsLoading: boolean;
	belongsToIsLoadingMore: boolean;
	belongsToProducts: RelatedProductCard[] | undefined;
	creditSimulationPopoverIsOpen: boolean;
	currentProductStock: ItemStockResponse | undefined;
	currentStoreStock: Stock | undefined;
	existingPrintPlacements: ExistingPlacement[] | undefined;
	fileUploadButtonState: ActionButtonState;
	fit3Summary: QuestionSummary | undefined;
	hasMoreReviews: boolean;
	isAboutBrandOpen: boolean;
	isBelongsToOpen: boolean;
	isImportantInfoOpen: boolean;
	isInitialLoadingProductPrint: boolean;
	isLoadingAllStoresStock: boolean;
	isLoadingFit: boolean;
	isLoadingInitialReviews: boolean;
	isLoadingInitialSimilarProducts: boolean;
	isLoadingInitialStock: boolean;
	isLoadingMoreReviews: boolean;
	isLoadingNearbyStoresStock: boolean;
	isLoadingProductStock: boolean;
	isLoadingVariantsStock: boolean;
	isProductQuestionsOpen: boolean;
	isPurchasable: boolean;
	isReviewsOpen: boolean;
	isSparePartsOpen: boolean;
	isTechnicalAttributesOpen: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onAboutBrandClick: () => void;
	onAccessoriesLoadMoreClick: () => void;
	onAddPrintPlacementClick: (newPrint: CreateCustomizationRequest) => void;
	onAllReviewImagesSlideChange: (slideIndex: number) => void;
	onAllReviewsImageClick: (id: ReviewImageId) => void;
	onBelongsToClick: () => void;
	onBelongsToLoadMoreClick: () => void;
	onCampaignPackageAddToCartClick:
		| ((campaign: CampaignResponse) => void)
		| undefined;
	onCampaignPopoverProductAddToCartClick: (
		variant: RelatedProductCard,
		campaign: CampaignResponse,
	) => void;
	onCreditSimulationClick: () => void;
	onCreditSimulationPopoverClose: () => void;
	onImportantInfoClick: () => void;
	onLoadMoreReviewsClick: () => void;
	onMainPurchaseButtonClick: () => void;
	onMainWishlistButtonClick: () => void;
	onProductQuestionsClick: () => void;
	onProductQuestionsLoadMoreClick: () => void;
	onRemovePrintClick: (id: string) => void;
	onRemovePrintPlacementClick: (placementId: string) => void;
	onReusePrintClick: (id: string) => void;
	onReviewGradeClick: (score: number) => void;
	onReviewGradeResetClick: () => void;
	onReviewsClick: () => void;
	onReviewsLinkClick: () => void;
	onReviewsSortOptionChange: (value: string | undefined) => void;
	onSelectedReviewImagesSlideChange: (slideIndex: number) => void;
	onSizeGuideClick: () => void;
	onSparePartsClick: () => void;
	onSparePartsLoadMoreClick: () => void;
	onStockInformationOpen: () => void;
	onTechnicalAttributesClick: () => void;
	onTopScoreReviewImageClick: (id: ReviewImageId) => void;
	onUpdateSelectedStore: (store: StoreIdName) => void;
	onUpLoadPrint: (file: File) => void;
	onVariantPickerOpen: () => void;
	onWishlistAddedProductPopoverClose: () => void;
	onWishlistAdditionalSalesButtonClick: (product: RelatedProductCard) => void;
	placements: Placement[] | undefined;
	print: StoredFileResponse | undefined;
	printPlacements: CustomizationPlacement[] | undefined;
	printUploadErrors: string[] | undefined;
	product: Product;
	productPrintHasError: boolean;
	productPrintTotalCost: Price | undefined;
	productQuestions: Question[] | undefined;
	productQuestionsHasNextPage: boolean;
	productQuestionsIsLoading: boolean;
	productQuestionsIsLoadingMore: boolean;
	productQuestionsNewQuestionsAllowed: boolean;
	purchaseButtonState: ButtonStateWithId;
	questionsSummary: QuestionSummary[] | undefined;
	reviewPolicyUrl: string | undefined;
	reviews: Review[] | undefined;
	reviewsSortOption: string | undefined;
	selectedAllReviewImageId: ReviewImageId | undefined;
	selectedStore: StoreIdName | undefined;
	selectedTopScoreReviewImageId: ReviewImageId | undefined;
	similarProducts: ProductCard[] | undefined;
	sparePartsHasNextPage: boolean;
	sparePartsIsLoading: boolean;
	sparePartsIsLoadingMore: boolean;
	sparePartsProducts: RelatedProductCard[] | undefined;
	storedPrints: StoredFileResponse[] | undefined;
	topScoreReviewImages: ReviewImage[] | undefined;
	variantsStock: ItemStockResponse[] | undefined;
	wishlistAddedProduct: RelatedProductCard | undefined;
	wishlistAddedProductPopoverIsOpen: boolean;
	wishlistAddedProductPopoverMessage: string | undefined;
	wishlistAdditionalSalesGTMItemListId: GTMItemListId;
	wishlistAdditionalSalesGTMItemListName: GTMItemListName;
	wishlistAdditionalSalesProducts: RelatedProductCard[] | undefined;
	wishlistButtonState: ButtonStateWithId;
}

export default function ProductDetails({
	accessoriesHasNextPage,
	accessoriesIsLoading,
	accessoriesIsLoadingMore,
	accessoriesProducts,
	addPrintPlacementButtonState,
	addPrintPlacementErrors,
	allReviewImages,
	allStoresStock,
	belongsToHasNextPage,
	belongsToIsLoading,
	belongsToIsLoadingMore,
	belongsToProducts,
	creditSimulationPopoverIsOpen,
	currentProductStock,
	currentStoreStock,
	existingPrintPlacements,
	fileUploadButtonState,
	fit3Summary,
	hasMoreReviews,
	isAboutBrandOpen,
	isBelongsToOpen,
	isImportantInfoOpen,
	isInitialLoadingProductPrint,
	isLoadingAllStoresStock,
	isLoadingFit,
	isLoadingInitialReviews,
	isLoadingInitialSimilarProducts,
	isLoadingInitialStock,
	isLoadingMoreReviews,
	isLoadingNearbyStoresStock,
	isLoadingProductStock,
	isLoadingVariantsStock,
	isProductQuestionsOpen,
	isPurchasable,
	isReviewsOpen,
	isSparePartsOpen,
	isTechnicalAttributesOpen,
	nearbyStoresStock,
	onAboutBrandClick,
	onAccessoriesLoadMoreClick,
	onAddPrintPlacementClick,
	onAllReviewImagesSlideChange,
	onAllReviewsImageClick,
	onBelongsToClick,
	onBelongsToLoadMoreClick,
	onCampaignPackageAddToCartClick,
	onCampaignPopoverProductAddToCartClick,
	onCreditSimulationClick,
	onCreditSimulationPopoverClose,
	onImportantInfoClick,
	onLoadMoreReviewsClick,
	onMainPurchaseButtonClick,
	onMainWishlistButtonClick,
	onProductQuestionsClick,
	onProductQuestionsLoadMoreClick,
	onRemovePrintClick,
	onRemovePrintPlacementClick,
	onReusePrintClick,
	onReviewGradeClick,
	onReviewGradeResetClick,
	onReviewsClick,
	onReviewsLinkClick,
	onReviewsSortOptionChange,
	onSelectedReviewImagesSlideChange,
	onSizeGuideClick,
	onSparePartsClick,
	onSparePartsLoadMoreClick,
	onStockInformationOpen,
	onTechnicalAttributesClick,
	onTopScoreReviewImageClick,
	onUpdateSelectedStore,
	onUpLoadPrint,
	onVariantPickerOpen,
	onWishlistAddedProductPopoverClose,
	onWishlistAdditionalSalesButtonClick,
	placements,
	print,
	printPlacements,
	printUploadErrors,
	product,
	productPrintHasError,
	productPrintTotalCost,
	productQuestions,
	productQuestionsHasNextPage,
	productQuestionsIsLoading,
	productQuestionsIsLoadingMore,
	productQuestionsNewQuestionsAllowed,
	purchaseButtonState,
	questionsSummary,
	reviewPolicyUrl,
	reviews,
	reviewsSortOption,
	selectedAllReviewImageId,
	selectedStore,
	selectedTopScoreReviewImageId,
	similarProducts,
	sparePartsHasNextPage,
	sparePartsIsLoading,
	sparePartsIsLoadingMore,
	sparePartsProducts,
	storedPrints,
	topScoreReviewImages,
	variantsStock,
	wishlistAddedProduct,
	wishlistAddedProductPopoverIsOpen,
	wishlistAddedProductPopoverMessage,
	wishlistAdditionalSalesGTMItemListId,
	wishlistAdditionalSalesGTMItemListName,
	wishlistAdditionalSalesProducts,
	wishlistButtonState,
}: Props) {
	const {
		brand,
		chemicalInformation,
		externalTestReview,
		hasAccessories,
		hasBelongsTo,
		hasSpareParts,
		id,
		packageSize,
		reviewScore,
		salesAttributes,
	} = product;
	const additionalTexts = filterAdditionalTexts(product.additionalTexts);
	const documents = getDocuments(product);
	const verticalEnergyLabel = getVerticalEnergyLabel(product);
	const technicalCategories = product.technicalAttributes?.categories;
	const externalTestReviewLogo = getExternalTestReviewLogo(
		externalTestReview?.logo,
	);
	const { t } = useI18n();

	const [productAndSelectedImagesIsOpen, setProductAndSelectedImagesIsOpen] =
		useState(false);
	const [allReviewImagesIsOpen, setAllReviewImagesIsOpen] = useState(false);
	const [activeImagesTabId, setActiveImagesTabId] =
		useState<ProductAndSelectedImagesTabId>('product-images');

	const openProductAndSelectedImages = (
		tabId: ProductAndSelectedImagesTabId,
	) => {
		setActiveImagesTabId(tabId);
		setProductAndSelectedImagesIsOpen(true);
	};

	const hasTechnicalAttributes =
		is.arrayWithLength(technicalCategories) ||
		is.arrayWithLength(documents) ||
		Boolean(packageSize);
	const hasProductQuestions =
		is.arrayWithLength(productQuestions) || productQuestionsNewQuestionsAllowed;
	const hasBrandInformation = Boolean(brand?.descriptionFormatted);
	const hasChemicalStatements = Boolean(
		chemicalInformation?.hazardStatements?.length ??
			chemicalInformation?.supplementalHazardStatements?.length ??
			chemicalInformation?.precationaryStatements?.length,
	);
	const hasImportantInformation =
		is.arrayWithLength(additionalTexts) || hasChemicalStatements;

	const contentClassName = 'max-w-[50rem]';

	return (
		<>
			<LayoutContainer
				className={cn(
					"[grid-template-areas:'a_a_a_a_a_a_a_a_a_a_a_a''b_b_b_b_b_b_b_._s_s_s_s''c_c_c_c_c_c_c_._s_s_s_s']",
					// Collapse the implicit a and b rows to min-content and let the c
					// row take the rest. This prevents whitespace from being distributed
					// between the rows if the sidebar is taller than the main content.
					'md:grid-rows-[min-content_min-content_1fr]',
					'md:grid md:grid-cols-12 md:gap-x-6',
				)}
			>
				{salesAttributes?.expired && (
					<ExpiredProductInfo
						className="md:hidden"
						shouldShowSimilarProductsLink={is.arrayWithLength(similarProducts)}
					/>
				)}

				<Text
					as="h1"
					className="mb-2 [grid-area:a] max-md:mt-1"
					text={
						product.titleExclModel && product.subTitle
							? `${product.titleExclModel} ${product.subTitle}`
							: (product.titleExclModel ?? product.title)
					}
				/>

				<div className="[grid-area:b]">
					<ProductDetailsIntro
						brand={brand}
						onRatingClick={onReviewsLinkClick}
						productModel={product.productModel}
						reviewScore={reviewScore}
						tags={product.productTags}
						variantModel={product.variantModel}
					/>
					<ProductDetailsImage
						className="mt-4 md:mt-12"
						product={product}
						productAndSelectedImagesIsOpen={productAndSelectedImagesIsOpen}
						allReviewImagesIsOpen={allReviewImagesIsOpen}
						activeTabId={activeImagesTabId}
						onTabChange={(newTabId) => {
							setActiveImagesTabId(newTabId);
						}}
						onOpenProductAndSelectedImages={() => {
							openProductAndSelectedImages('product-images');
						}}
						onCloseProductAndSelectedImages={() => {
							setProductAndSelectedImagesIsOpen(false);
						}}
						onAllReviewImagesSlideChange={onAllReviewImagesSlideChange}
						onSelectedReviewImagesSlideChange={
							onSelectedReviewImagesSlideChange
						}
						onCloseAllReviewImages={() => {
							setAllReviewImagesIsOpen(false);
						}}
						topScoreReviewImages={topScoreReviewImages}
						selectedTopScoreReviewImageId={selectedTopScoreReviewImageId}
						allReviewImages={allReviewImages}
						selectedAllReviewImageId={selectedAllReviewImageId}
					/>
				</div>

				<div className="[grid-area:s] max-md:mt-4">
					<ErrorBoundary>
						{salesAttributes?.expired && (
							<ExpiredProductInfo
								className="max-md:hidden"
								shouldShowSimilarProductsLink={is.arrayWithLength(
									similarProducts,
								)}
							/>
						)}
						{!salesAttributes?.expired && (
							<ProductDetailsSidebar
								isInitialLoadingProductPrint={isInitialLoadingProductPrint}
								productPrintHasError={productPrintHasError}
								product={product}
								onEnergyLabelClick={onTechnicalAttributesClick}
								onSizeFitClick={onReviewsLinkClick}
								fit3Summary={fit3Summary}
								isLoadingFit={isLoadingFit}
								allStoresStock={allStoresStock}
								currentProductStock={currentProductStock}
								onCampaignPopoverProductAddToCartClick={
									onCampaignPopoverProductAddToCartClick
								}
								currentStoreStock={currentStoreStock}
								isLoadingInitialStock={isLoadingInitialStock}
								isLoadingAllStoresStock={isLoadingAllStoresStock}
								isLoadingNearbyStoresStock={isLoadingNearbyStoresStock}
								purchaseButtonState={purchaseButtonState}
								onMainPurchaseButtonClick={onMainPurchaseButtonClick}
								wishlistButtonState={wishlistButtonState}
								onWishlistButtonClick={onMainWishlistButtonClick}
								onStockInformationOpen={onStockInformationOpen}
								onSizeGuideClick={onSizeGuideClick}
								onVariantPickerOpen={onVariantPickerOpen}
								creditSimulationPopoverIsOpen={creditSimulationPopoverIsOpen}
								onCreditSimulationPopoverClose={onCreditSimulationPopoverClose}
								onCreditSimulationClick={onCreditSimulationClick}
								variantsStock={variantsStock}
								selectedStore={selectedStore}
								isLoadingVariantsStock={isLoadingVariantsStock}
								isPurchasable={isPurchasable}
								onUpdateSelectedStore={onUpdateSelectedStore}
								nearbyStoresStock={nearbyStoresStock}
								isLoadingProductStock={isLoadingProductStock}
								addPrintPlacementButtonState={addPrintPlacementButtonState}
								addPrintPlacementErrors={addPrintPlacementErrors}
								existingPrintPlacements={existingPrintPlacements}
								fileUploadButtonState={fileUploadButtonState}
								onAddPrintPlacementClick={onAddPrintPlacementClick}
								onRemovePrintPlacementClick={onRemovePrintPlacementClick}
								onReusePrintClick={onReusePrintClick}
								onUpLoadPrint={onUpLoadPrint}
								placements={placements}
								print={print}
								printPlacements={printPlacements}
								productPrintTotalCost={productPrintTotalCost}
								storedPrints={storedPrints}
								onRemovePrintClick={onRemovePrintClick}
								printUploadErrors={printUploadErrors}
							/>
						)}
					</ErrorBoundary>
				</div>

				<div className="mt-12 [grid-area:c]">
					{externalTestReview?.result && externalTestReview.source && (
						<ProductDetailsExternalReview
							className="mb-8 max-w-[33rem] md:mb-12"
							features={externalTestReview?.features}
							heading={externalTestReview.result}
							logoAlt={externalTestReviewLogo?.alt}
							logoHeight={externalTestReviewLogo?.height}
							logoSrc={externalTestReviewLogo?.src}
							logoWidth={externalTestReviewLogo?.width}
							source={externalTestReview.source}
							sourceUrl={externalTestReview?.sourceUrl}
						/>
					)}
					<ProductDetailsCampaignPackages
						className="mb-8 md:mb-12"
						campaigns={product.campaigns}
						onAddToCartClick={onCampaignPackageAddToCartClick}
						purchaseButtonState={purchaseButtonState}
					/>

					<ProductDetailsDescription
						product={product}
						descriptionClassName={contentClassName}
						isExpanded={hasChemicalStatements}
						onImportantInfoClick={onImportantInfoClick}
					/>

					{hasTechnicalAttributes && (
						<Accordion
							id={PRODUCT_TECHNICAL_ATTRIBUTES_ID}
							title={t('product_details_technical_specification_heading')}
							titleTag="h2"
							isOpen={isTechnicalAttributesOpen}
							onClick={onTechnicalAttributesClick}
						>
							<TechnicalSpecification
								className={cn(contentClassName, 'my-4')}
								categories={technicalCategories}
								packageSize={packageSize}
								energyLabelSrc={verticalEnergyLabel?.url?.location}
								documents={documents}
							/>
						</Accordion>
					)}
					{reviewScore && (
						<Accordion
							id={PRODUCT_REVIEWS_ID}
							title={`${t('product_details_reviews_heading')} (${
								reviewScore.count
							})`}
							titleTag="h2"
							isOpen={isReviewsOpen}
							onClick={onReviewsClick}
						>
							<Reviews
								className={contentClassName}
								isLoadingMoreReviews={Boolean(isLoadingMoreReviews)}
								hasMoreReviews={hasMoreReviews}
								onGradeClick={onReviewGradeClick}
								onGradeResetClick={onReviewGradeResetClick}
								onLoadMoreClick={onLoadMoreReviewsClick}
								ratingsPerGrade={[
									reviewScore.countPerGrade.grade5,
									reviewScore.countPerGrade.grade4,
									reviewScore.countPerGrade.grade3,
									reviewScore.countPerGrade.grade2,
									reviewScore.countPerGrade.grade1,
								]}
								reviewItems={reviews}
								topScoreReviewImages={topScoreReviewImages}
								onShowTopScoreReviewImages={() => {
									openProductAndSelectedImages('review-images');
								}}
								onTopScoreReviewImageClick={(imageId) => {
									onTopScoreReviewImageClick(imageId);
									openProductAndSelectedImages('review-images');
								}}
								onReviewImageClick={(imageId) => {
									onAllReviewsImageClick(imageId);
									setAllReviewImagesIsOpen(true);
								}}
								reviewPolicyUrl={reviewPolicyUrl}
								score={reviewScore.score}
								totalReviews={reviewScore.count || 0}
								questionsSummary={questionsSummary}
								sortOption={reviewsSortOption}
								onSortOptionChange={onReviewsSortOptionChange}
								isLoading={isLoadingInitialReviews}
							/>
						</Accordion>
					)}
					{hasProductQuestions && (
						<ProductQuestions
							questions={productQuestions}
							newQuestionsAllowed={productQuestionsNewQuestionsAllowed}
							hasNextPage={productQuestionsHasNextPage}
							onLoadMoreClick={onProductQuestionsLoadMoreClick}
							isOpen={isProductQuestionsOpen}
							isLoading={productQuestionsIsLoading}
							isLoadingMore={productQuestionsIsLoadingMore}
							onClick={onProductQuestionsClick}
							productName={product.title}
							productId={id}
						/>
					)}
					{hasAccessories && (
						<RelatedProductsAccordion
							products={accessoriesProducts}
							isLoadingInitial={accessoriesIsLoading}
							isLoadingMore={accessoriesIsLoadingMore}
							hasNextPage={accessoriesHasNextPage}
							onLoadMoreClick={onAccessoriesLoadMoreClick}
							title={t('product_details_accessories_heading')}
							accordionId={PRODUCT_ACCESSORIES_ID}
							contentClassName={contentClassName}
							initialOpen
							gtmItemListId="pdp_accessories"
							gtmItemListName={`List: Accessories ${id}`}
							onAddToCart={() => {
								sendGlobalEvent('engagement', {
									type: 'addAccessoryToCart',
								});
							}}
						/>
					)}
					{hasSpareParts && (
						<RelatedProductsAccordion
							products={sparePartsProducts}
							isLoadingInitial={sparePartsIsLoading}
							isLoadingMore={sparePartsIsLoadingMore}
							hasNextPage={sparePartsHasNextPage}
							onLoadMoreClick={onSparePartsLoadMoreClick}
							title={t('product_details_spare_parts_heading')}
							accordionId={PRODUCT_SPARE_PARTS_ID}
							contentClassName={contentClassName}
							isOpen={isSparePartsOpen}
							gtmItemListId="pdp_spare_parts"
							gtmItemListName={`List: Spare parts ${id}`}
							onClick={onSparePartsClick}
						/>
					)}
					{hasBelongsTo && (
						<RelatedProductsAccordion
							products={belongsToProducts}
							isLoadingInitial={belongsToIsLoading}
							isLoadingMore={belongsToIsLoadingMore}
							hasNextPage={belongsToHasNextPage}
							onLoadMoreClick={onBelongsToLoadMoreClick}
							title={t('product_details_belong_to_heading')}
							accordionId={PRODUCT_BELONGS_TO_ID}
							contentClassName={contentClassName}
							isOpen={isBelongsToOpen}
							gtmItemListId="pdp_belongs_to"
							gtmItemListName={`List: Belongs to ${id}`}
							onClick={onBelongsToClick}
							afterListContent={
								<InfoBox
									icon="info"
									message={t(
										'product_details_belongs_to_accordion_may_have_more_products_text',
									)}
									className="mt-4"
								/>
							}
						/>
					)}
					{hasBrandInformation && (
						<Accordion
							id="about-brand"
							title={t('product_details_about_brand_heading')}
							titleTag="h2"
							isOpen={isAboutBrandOpen}
							onClick={onAboutBrandClick}
						>
							<ProductDetailsBrandInfo
								className={cn(contentClassName, 'mb-6 md:mb-6')}
								product={product}
							/>
						</Accordion>
					)}
					{hasImportantInformation && (
						<Accordion
							id={PRODUCT_IMPORTANT_INFO_ID}
							title={t('product_details_important_information_heading')}
							titleTag="h2"
							isOpen={isImportantInfoOpen}
							onClick={onImportantInfoClick}
						>
							<ProductDetailsImportantInfo
								className={contentClassName}
								hasChemicalStatements={hasChemicalStatements}
								product={product}
							/>
						</Accordion>
					)}
				</div>
			</LayoutContainer>

			<ProductDetailsSimilarProducts
				className="mt-14 md:mt-10"
				isLoadingInitialSimilarProducts={isLoadingInitialSimilarProducts}
				productId={id}
				similarProducts={similarProducts}
			/>
			<AddedProductPopover
				buttonState={wishlistButtonState}
				buttonText={t(
					'product_details_wishlist_additional_sales_popover_button',
				)}
				gtmItemListId={wishlistAdditionalSalesGTMItemListId}
				gtmItemListName={wishlistAdditionalSalesGTMItemListName}
				id="additional-sales-wishlist"
				testId="additional-sales-wishlist"
				isOpen={wishlistAddedProductPopoverIsOpen}
				onAddItem={onWishlistAdditionalSalesButtonClick}
				onClose={onWishlistAddedProductPopoverClose}
				products={wishlistAdditionalSalesProducts}
				addedProduct={wishlistAddedProduct}
				title={wishlistAddedProductPopoverMessage ?? ''}
				addedLocationLink="/wishlist/"
				goToAddedLocationButtonText={t('continue_to_wishlist')}
			/>
		</>
	);
}
ProductDetails.displayName = 'ProductDetails';
