import React from 'react';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import { File } from 'components/FormUi';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import Link from 'components/Link';
import Price from 'components/Price';
import type { StoredFileResponse } from 'models/api/userFileStorage';
import type { Price as PriceModel } from 'models/price';
import { cn } from 'utils/classNames';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface IconTextButtonProps {
	buttonText: string | undefined;
	className?: string;
	iconText: string | undefined;
	onClick: () => void;
	url: string;
}

function IconTextButton({
	buttonText,
	className,
	iconText,
	onClick,
	url,
}: IconTextButtonProps) {
	return (
		<div className={cn('flex items-center justify-between', className)}>
			<Link href={url} underline className="flex items-center" target="_blank">
				<Icon name="file" className="mr-2" />
				{iconText}
			</Link>
			<Button variant="text" onClick={onClick}>
				{buttonText}
			</Button>
		</div>
	);
}
IconTextButton.displayName = 'ProductPrintPopover_IconTextButton';

interface Props {
	abortButtonText: string | undefined;
	addPrintButtonText: string | undefined;
	addPrintPlacementButtonState: ActionButtonState;
	description: string | undefined;
	fileUploadButtonState: ActionButtonState;
	infoText: string | undefined;
	onAbortClick: () => void;
	onAddPrintPlacementClick: () => void;
	onRemovePrintClick: (id: string) => void;
	onReusePrintClick: (id: string) => void;
	onUpLoadPrint: (file: File) => void;
	price: PriceModel | undefined;
	print: StoredFileResponse | undefined;
	printPriceLabel: string | undefined;
	printUploadErrors?: string[] | undefined;
	removeUploadedPrintButtonText: string | undefined;
	reusePrintButtonText: string | undefined;
	reuseUploadedPrintText: string | undefined;
	storedPrints: StoredFileResponse[] | undefined;
	uploadPrintButtonText: string | undefined;
}

export default function PrintUploadView({
	abortButtonText,
	addPrintButtonText,
	addPrintPlacementButtonState,
	description,
	fileUploadButtonState,
	infoText,
	onAbortClick,
	onAddPrintPlacementClick,
	onRemovePrintClick,
	onReusePrintClick,
	onUpLoadPrint,
	price,
	print,
	printPriceLabel,
	printUploadErrors,
	removeUploadedPrintButtonText,
	reusePrintButtonText,
	reuseUploadedPrintText,
	storedPrints,
	uploadPrintButtonText,
}: Props) {
	const { t } = useI18n();

	const printOriginalFileName = print?.originalFileName;
	const printFileName = print?.fileName;
	const printUrl = print?.url;
	const filteredStoredPrints = filterTruthy(
		storedPrints,
		'originalFileName',
		'fileName',
		'url',
	);

	return (
		<>
			<p>{description}</p>
			<InfoBox
				className="mt-6"
				icon="info"
				variant="information"
				message={infoText}
			/>
			<div className="mt-6">
				{!print && (
					<File
						className="inline-block"
						id="print-upload-file-input"
						label={uploadPrintButtonText ?? ''}
						onChange={onUpLoadPrint}
						uploadState={fileUploadButtonState}
						errorMessage={printUploadErrors}
						helpText={t('product_print_file_input_file_size_limit_text')}
					/>
				)}
				{printOriginalFileName && printFileName && printUrl && (
					<IconTextButton
						buttonText={removeUploadedPrintButtonText}
						iconText={printOriginalFileName}
						onClick={() => onRemovePrintClick(printFileName)}
						url={printUrl}
					/>
				)}
			</div>
			{!print && is.arrayWithLength(filteredStoredPrints) && (
				<div className="mt-8">
					<p className="font-bold">{reuseUploadedPrintText}</p>
					<ul className="flex flex-col gap-2">
						{filteredStoredPrints.map((storedPrint) => (
							<li key={storedPrint.fileName}>
								<IconTextButton
									buttonText={reusePrintButtonText}
									iconText={storedPrint.originalFileName}
									onClick={() => onReusePrintClick(storedPrint.fileName)}
									url={storedPrint.url}
								/>
							</li>
						))}
					</ul>
				</div>
			)}
			<div className="mt-6 flex justify-between">
				<p className="self-end font-bold">{printPriceLabel}</p>
				{price && <Price price={price} size="small" />}
			</div>
			<ActionButton
				onClick={onAddPrintPlacementClick}
				disabled={!print}
				variant="cta"
				displayWidth="full"
				className="mt-6"
				customState={addPrintPlacementButtonState}
			>
				{addPrintButtonText}
			</ActionButton>
			<Button
				className="mt-4"
				displayWidth="full"
				variant="text"
				onClick={onAbortClick}
			>
				{abortButtonText}
			</Button>
		</>
	);
}

PrintUploadView.displayName = 'ProductPrintPopover_PrintUploadView';
