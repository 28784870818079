import React, { useState } from 'react';

import Img from 'components/Img';
import PlainButton from 'components/PlainButton';
import { ProductResponse } from 'models/api/variant';
import {
	getAllImages,
	getMainImageDetail,
	Thumbnail,
} from 'utils/business-logic';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
	product: ProductResponse;
}

export default function ProductPopoverImage({ className, product }: Props) {
	const { t } = useI18n();
	const noPicsMain: Thumbnail = {
		alt: '',
		id: 1,
		src: '/assets/images/placeholder-image@2x.png',
	};
	const [selectedImg, setSelectedImg] = useState(
		getMainImageDetail(product) ?? noPicsMain,
	);

	const noPics = [noPicsMain];
	const allImages = getAllImages(product) ?? noPics;
	return (
		<div className={className}>
			<div className="flex justify-center">
				<Img service="nextjs" src={selectedImg.src} width={272} height={272} />
			</div>
			<div className="mb-6 flex flex-wrap gap-2">
				{allImages.map((img, i) => (
					<PlainButton
						key={img.id}
						onClick={() => {
							setSelectedImg(img);
						}}
						aria-label={t('product_details_show_image_number_label', {
							num: i + 1,
						})}
						className={cn(
							'group w-16 rounded-button border p-2',
							selectedImg.id === img.id &&
								'border-grey hover:border-greyDarker',
							selectedImg.id !== img.id && 'border-greyLight hover:border-grey',
						)}
					>
						<Img
							alt={img.alt}
							service="nextjs"
							width={64}
							height={64}
							className="w-full group-hover:scale-[1.07]"
							src={img.src}
						/>
					</PlainButton>
				))}
			</div>
		</div>
	);
}
ProductPopoverImage.displayName = 'ProductPopoverImage';
