/**
 * GiftCard
 */

import React from 'react';

import Button from 'components/Button';
import ChunkedText from 'components/ChunkedText';
import Text from 'components/Text';
import { FormattedPriceResponse } from 'models/api';
import { formatPriceText } from 'utils/format';
import { useI18n } from 'utils/i18n';

interface Props {
	/** The amount that will be used for this cart. 0 means that the voucher is not needed. */
	amountToUse: FormattedPriceResponse | undefined;

	/** The available balance on the voucher. */
	balance: FormattedPriceResponse | undefined;

	/** The available balance on the voucher after purchase. */
	balanceAfterPurchase: FormattedPriceResponse | undefined;

	id: string;

	onRemoveClick?: () => void;
}

export default function GiftCard({
	amountToUse,
	balance,
	balanceAfterPurchase,
	id,
	onRemoveClick,
}: Props) {
	const { t } = useI18n();

	return (
		<Text
			as="div"
			className="rounded-border border-2 border-success p-4 md:p-6"
		>
			<div className="mb-4 flex justify-between font-bold">
				<p className="mr-4">
					{t('checkout_gift_card_number_label')}{' '}
					<ChunkedText text={id} chunkSize={4} />
				</p>
				<p className="whitespace-nowrap">
					{formatPriceText(
						amountToUse?.displayValue,
						amountToUse?.displaySymbol,
					)}
				</p>
			</div>
			<div className="flex items-end justify-between text-sm">
				<p className="text-sm">
					{t('checkout_gift_card_amount_before_label')}{' '}
					{formatPriceText(balance?.displayValue, balance?.displaySymbol)}
					<br />
					{t('checkout_gift_card_amount_after_label')}{' '}
					{formatPriceText(
						balanceAfterPurchase?.displayValue,
						balanceAfterPurchase?.displaySymbol,
					)}
				</p>

				{onRemoveClick && (
					<Button
						size="small"
						variant="text"
						// Negative margin to compensate for the button's padding
						className="-mb-1 text-right"
						onClick={onRemoveClick}
					>
						{t('checkout_gift_card_remove_button')}
					</Button>
				)}
			</div>
		</Text>
	);
}
GiftCard.displayName = 'GiftCard';
