import React from 'react';
import { useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import ActionButton from 'components/ActionButton';
import CartListItem from 'components/CartListItem';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import PaymentMethodLogos from 'components/PaymentMethodLogos';
import { hasReducedPrice } from 'components/Price';
import { CartSummary } from 'components/Summary';
import Text from 'components/Text';
import { useFeatureToggle, useGlobalMachinesContext } from 'contexts';
import {
	useEffectOnce,
	useIsEditing,
	useProductGTMEvents,
	useShareList,
} from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { Variant } from 'models/api';
import type { DigizuiteAsset } from 'models/asset';
import { selectIsAuthenticatedUser } from 'state-machines/authentication';
import {
	selectAnonUserIsReadyForCheckout,
	selectBonusDiscounts,
	selectCampaignDiscount,
	selectCartId,
	selectCheckoutStatus,
	selectErrorList,
	selectFreightDiscount,
	selectGiftCards,
	selectIsLoadingCart,
	selectSavingTotalSumSummary,
	selectTotalSummary,
	selectVariants,
} from 'state-machines/cart/';
import { getProductImageSrc } from 'utils/business-logic';
import { pushToGTM } from 'utils/GoogleTagManager';
import { is, isThereError } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		svgImages: {
			value: {
				assets: DigizuiteAsset[][];
			};
		};
	};
};

export default function CartPage({ fields }: Props) {
	const { cartService, userService, wishlistService } =
		useGlobalMachinesContext();
	const isEditing = useIsEditing();
	const { onlineCommerceEnabled, wishlistEnabled } = useFeatureToggle();
	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);
	const isLoadingCart = useSelector(cartService, selectIsLoadingCart);
	const variants = useSelector(cartService, selectVariants);
	const anonUserIsReadyForCheckout = useSelector(
		cartService,
		selectAnonUserIsReadyForCheckout,
	);

	const cartId = useSelector(cartService, selectCartId);
	const errorList = useSelector(cartService, selectErrorList);
	const checkoutStatus = useSelector(cartService, selectCheckoutStatus);
	const campaignDiscount = useSelector(cartService, selectCampaignDiscount);
	const freightDiscount = useSelector(cartService, selectFreightDiscount);
	const giftCards = useSelector(cartService, selectGiftCards);
	const bonusDiscounts = useSelector(cartService, selectBonusDiscounts);
	const totalSummary = useSelector(cartService, selectTotalSummary);

	const savingTotalSumSummary = useSelector(
		cartService,
		selectSavingTotalSumSummary,
	);
	const { copyListUrl, shareListUrl, shareState } = useShareList({
		type: 'cart',
		cartId,
	});
	const { sendAddToCartEvent, sendRemoveFromCartEvent } = useProductGTMEvents();

	const maxQtyError = errorList?.find(
		(error) =>
			error.type === 'ClearedCartMaxQty' || error.type === 'UniqueItemsMaxQty',
	);

	const onDiscountCodeSubmit = async (values: Record<string, number>) => {
		let code: string;
		if (values.discountCode) {
			code = values.discountCode?.toString();
			cartService.send({
				type: 'EDIT_DISCOUNT_CODES',
				discountCode: code,
				variant: 'add',
			});
			const doneData = await waitFor(cartService, (state) =>
				state.matches('idle'),
			);

			const discountNotFoundError = doneData.context.cart?.errorList?.find(
				(error) =>
					error.type === 'DiscountCodeNotFound' ||
					error.type === 'DiscountExpired' ||
					error.type === 'DiscountInvalid',
			);
			if (discountNotFoundError) {
				return {
					discountCode: discountNotFoundError.description,
				};
			}

			const businessLogicErrors = doneData.context.cart?.errorList?.filter(
				(error) =>
					error.type === 'DiscountCountError' ||
					error.type === 'DiscountCombinationInvalid',
			);

			if (is.arrayWithLength(businessLogicErrors)) {
				return { FORM_ERROR: 'businessLogicErrors' };
			}
			return undefined;
		}
		return undefined;
	};

	const deleteDiscountCode = (code: string) => {
		const variant = 'remove';

		cartService.send({
			type: 'EDIT_DISCOUNT_CODES',
			discountCode: code,
			variant,
		});
	};

	const moveVariantToWishlist = (variant: Variant) => {
		wishlistService.send({
			type: 'CHANGE_VARIANT_QTY',
			qty: variant.qty,
			variantId: variant.id,
			GTMData: {
				type: 'add_to_wishlist',
				payload: { product: variant, quantity: variant.qty },
			},
		});
		cartService.send({
			type: 'ADD_REMOVE_VARIANT',
			variantId: variant.id,
			qty: 0,
			distinctValue: true,
		});
		sendRemoveFromCartEvent(variant, variant.qty);
	};

	const { t } = useI18n();

	useEffectOnce(() => {
		if (variants) {
			pushToGTM({
				type: 'view_cart',
				payload: { products: variants, cartId },
			});
		}
	});

	if (isEditing) {
		return (
			<div className="mx-4 my-4 h-full max-w-pageNarrow md:mx-auto">
				<ComponentPlaceholder componentName="Cart" />
			</div>
		);
	}

	const shouldGoStraightToCheckout =
		isAuthenticatedUser || anonUserIsReadyForCheckout;

	return (
		<>
			{isLoadingCart && (
				<LoadingSpinner
					variant="dashing"
					spinnerColor="julaRed"
					trackColor="transparent"
					className="fixed left-1/2 top-1/2 z-pageLoadingSpinner -mb-20 -ml-20 -mt-20 mr-0"
				/>
			)}
			<LayoutContainer withGrid outerClassName="mt-8">
				<div className="col-span-full md:col-end-9 lg:col-end-8">
					<div className="mb-4 flex justify-between md:mb-8">
						<Text
							as="h1"
							className="text-greyDarker"
							text={t('cart_cart_heading')}
						/>
						<ActionButton
							customState={shareState}
							showSuccess={false}
							rounded
							onClick={shareListUrl}
							className="md:hidden"
						>
							<Icon name="share" className="mr-2" />
							{t('list_share_link_button')}
						</ActionButton>
						<ActionButton
							customState={shareState}
							showSuccess={false}
							rounded
							onClick={copyListUrl}
							className="max-md:hidden"
						>
							<Icon name="copyLink" className="mr-2" />

							{t('list_copy_link_button')}
						</ActionButton>
					</div>
					{maxQtyError && (
						<InfoBox icon="info" message={maxQtyError.description} />
					)}
					<div className="flex w-full justify-between max-md:hidden">
						<Text as="p" className="mb-2 font-bold">
							{t('cart_line_products_label')}
						</Text>
						<Text as="p" className="mb-2 font-bold">
							{t('cart_line_total_label')}
						</Text>
					</div>

					<div className="divide-y divide-grey border-y border-grey">
						{checkoutStatus !== 'OrderCreated' &&
							checkoutStatus !== 'Paid' &&
							variants?.map((variant) => {
								const webStock = isThereError(
									'VariantNotSellable',
									errorList,
									variant.id,
								)
									? undefined
									: variant.webStock;

								const hasCustomization = variant.customization !== undefined;

								const shouldShowWishlistButton =
									wishlistEnabled && !hasCustomization;

								return (
									<CartListItem
										key={variant.customization?.id ?? variant.id}
										reducedPrice={hasReducedPrice(variant.rowSummary.priceType)}
										rowSummary={variant?.rowSummary}
										quantity={variant.qty}
										webStock={webStock}
										onUpdateVariantQty={
											onlineCommerceEnabled
												? (quantity: number) => {
														cartService.send({
															type: 'ADD_REMOVE_VARIANT',
															variantId: variant.id,
															customizationId: variant.customization?.id,
															qty: quantity,
															distinctValue: true,
															metaData: variant.metaData,
														});
														if (variant.qty - quantity > 0) {
															sendRemoveFromCartEvent(
																variant,
																Math.abs(variant.qty - quantity),
															);
														} else {
															sendAddToCartEvent({
																product: variant,
																quantity: Math.abs(variant.qty - quantity),
																itemListId: variant.metaData?.gtmItemListId,
																itemListName: variant.metaData?.gtmItemListName,
															});
														}
													}
												: undefined
										}
										removeVariantButtonText={t('cart_remove_from_cart_button')}
										onRemoveVariant={
											onlineCommerceEnabled
												? () => {
														cartService.send({
															type: 'ADD_REMOVE_VARIANT',
															variantId: variant.id,
															customizationId: variant.customization?.id,
															qty: 0,
															distinctValue: true,
														});
														sendRemoveFromCartEvent(variant, variant.qty);
													}
												: undefined
										}
										moveVariantToWishlistButtonText={t(
											'cart_move_to_wishlist_button',
										)}
										onMoveVariantToWishlist={
											shouldShowWishlistButton
												? () => moveVariantToWishlist(variant)
												: undefined
										}
										link={variant.url}
										imageSrc={getProductImageSrc(
											variant.listImageFormats,
											true,
										)}
										imageAlt={variant.title || ''}
										heading={variant.title}
										id={variant.id}
										price={variant.price}
										volumePrice={variant.volumePrice}
										placements={variant.customization?.placements}
										invalidQuantity={isThereError(
											'VariantInsufficientStock',
											errorList,
											variant.id,
										)}
										errors={errorList?.filter(
											(error) =>
												error.key === variant.id ||
												error.key === variant.customization?.id,
										)}
									/>
								);
							})}
					</div>
				</div>
				<div className="col-span-full max-md:mt-8 md:col-start-9">
					{checkoutStatus !== 'OrderCreated' && checkoutStatus !== 'Paid' && (
						<CartSummary
							heading={t('cart_summary_heading')}
							checkoutButtonHref={
								shouldGoStraightToCheckout ? '/checkout' : '/identification'
							}
							errorList={errorList}
							disableButton={
								!variants ||
								variants.length === 0 ||
								isThereError('VariantOutOfStock', errorList) ||
								isThereError('VariantInsufficientStock', errorList) ||
								isThereError('VariantNotSellable', errorList) ||
								isThereError('CustomizationInvalid', errorList) ||
								!onlineCommerceEnabled
							}
							onDiscountCodeSubmit={onDiscountCodeSubmit}
							campaignDiscount={campaignDiscount}
							freightDiscount={freightDiscount}
							deleteDiscountCode={deleteDiscountCode}
							discountCountError={isThereError('DiscountCountError', errorList)}
							discountCombinationError={isThereError(
								'DiscountCombinationInvalid',
								errorList,
							)}
							totalSummary={totalSummary}
							giftCards={giftCards}
							bonusDiscounts={bonusDiscounts}
							savingTotalSumSummary={savingTotalSumSummary}
						/>
					)}
					<PaymentMethodLogos
						className="mt-8"
						heading={t('cart_accepted_payment_methods_heading')}
						images={fields?.svgImages.value.assets.flat()}
					/>
				</div>
			</LayoutContainer>
		</>
	);
}
CartPage.displayName = 'CartPage';
