import React from 'react';

import { ActionButtonState } from 'components/ActionButton';
import { ErrorMessageInfoBox } from 'components/InfoBox';
import Popover from 'components/Popover';
import type { ValidationError } from 'errors';
import type { ExistingPlacement } from 'hooks/product-details';
import {
	CreateCustomizationRequest,
	Option,
	Placement,
} from 'models/api/ProductCustomization';
import { StoredFileResponse } from 'models/api/userFileStorage';
import { Price } from 'models/price';
import { is } from 'utils/helpers';

import ExistingPrintPlacementsView from './ExistingPrintPlacementsView';
import PlacementView, { SelectablePlacement } from './PlacementView';
import PrintUploadView from './PrintUploadView';
import SizeView from './SizeView';

interface Props {
	addNewPrintPlacementButtonText: string | undefined;
	addPrintPlacementButtonState: ActionButtonState;
	addPrintPlacementButtonText: string | undefined;
	addPrintPlacementErrors: ValidationError | undefined;
	currentPageIndex: number;
	existingPrintPlacements: ExistingPlacement[] | undefined;
	existingPrintPlacementsDescription: string | undefined;
	existingPrintPlacementsHeading: string | undefined;
	existingPrintPlacementsListHeading: string | undefined;
	fileUploadButtonState: ActionButtonState;
	isOpen: boolean;
	onAddPrintPlacementClick: () => void;
	onBackClick: () => void;
	onClose: () => void;
	onCloseDone: () => void;
	onCreateNewPrintPlacementClick: () => void;
	onPlacementClick: (placement: Placement) => void;
	onPrintUploadAbortClick: () => void;
	onRemovePrintClick: (id: string) => void;
	onReusePrintClick: (id: string) => void;
	onSelectExistingPrintPlacementClick: (
		newPrint: CreateCustomizationRequest,
	) => void;
	onSizeClick: (sizeOption: Option) => void;
	onUpLoadPrint: (file: File) => void;
	placementDisabledText: string | undefined;
	placementHeading: string | undefined;
	placements: SelectablePlacement[] | undefined;
	popoverFallbackHeading: string;
	print: StoredFileResponse | undefined;
	printUploadAbortButtonText: string | undefined;
	printUploadDescription: string | undefined;
	printUploadErrors: string[] | undefined;
	printUploadHeading: string | undefined;
	printUploadInfoText: string | undefined;
	printUploadPriceLabel: string | undefined;
	removeUploadedPrintButtonText: string | undefined;
	reusePrintButtonText: string | undefined;
	reuseUploadedPrintText: string | undefined;
	selectedPlacementPrice: Price | undefined;
	selectExistingPrintPlacementButtonText: string | undefined;
	sizeHeading: string | undefined;
	sizes: Option[] | undefined;
	storedPrints: StoredFileResponse[] | undefined;
	uploadButtonText: string | undefined;
}

export default function ProductPrintPopover({
	addNewPrintPlacementButtonText,
	addPrintPlacementButtonState,
	addPrintPlacementButtonText,
	addPrintPlacementErrors,
	currentPageIndex,
	existingPrintPlacements,
	existingPrintPlacementsDescription,
	existingPrintPlacementsHeading,
	existingPrintPlacementsListHeading,
	fileUploadButtonState,
	isOpen,
	onAddPrintPlacementClick,
	onBackClick,
	onClose,
	onCloseDone,
	onCreateNewPrintPlacementClick,
	onPlacementClick,
	onPrintUploadAbortClick,
	onRemovePrintClick,
	onReusePrintClick,
	onSelectExistingPrintPlacementClick,
	onSizeClick,
	onUpLoadPrint,
	placementDisabledText,
	placementHeading,
	placements,
	popoverFallbackHeading,
	print,
	printUploadAbortButtonText,
	printUploadDescription,
	printUploadErrors,
	printUploadHeading,
	printUploadInfoText,
	printUploadPriceLabel,
	removeUploadedPrintButtonText,
	reusePrintButtonText,
	reuseUploadedPrintText,
	selectedPlacementPrice,
	selectExistingPrintPlacementButtonText,
	sizeHeading,
	sizes,
	storedPrints,
	uploadButtonText,
}: Props) {
	const pages = [
		{
			title: placementHeading || popoverFallbackHeading,
			content: (
				<PlacementView
					placements={placements}
					onPlacementClick={onPlacementClick}
					placementDisabledText={placementDisabledText}
				/>
			),
		},
		{
			title: sizeHeading || popoverFallbackHeading,
			content: <SizeView onSizeClick={onSizeClick} sizes={sizes} />,
		},
		{
			title: printUploadHeading || popoverFallbackHeading,
			content: (
				<PrintUploadView
					onAbortClick={onPrintUploadAbortClick}
					onAddPrintPlacementClick={onAddPrintPlacementClick}
					print={print}
					price={selectedPlacementPrice}
					storedPrints={storedPrints}
					onReusePrintClick={onReusePrintClick}
					onUpLoadPrint={onUpLoadPrint}
					fileUploadButtonState={fileUploadButtonState}
					onRemovePrintClick={onRemovePrintClick}
					addPrintPlacementButtonState={addPrintPlacementButtonState}
					abortButtonText={printUploadAbortButtonText}
					addPrintButtonText={addPrintPlacementButtonText}
					description={printUploadDescription}
					infoText={printUploadInfoText}
					printPriceLabel={printUploadPriceLabel}
					removeUploadedPrintButtonText={removeUploadedPrintButtonText}
					reusePrintButtonText={reusePrintButtonText}
					reuseUploadedPrintText={reuseUploadedPrintText}
					uploadPrintButtonText={uploadButtonText}
					printUploadErrors={printUploadErrors}
				/>
			),
		},
		{
			title: existingPrintPlacementsHeading || popoverFallbackHeading,
			content: (
				<ExistingPrintPlacementsView
					description={existingPrintPlacementsDescription}
					printPlacements={existingPrintPlacements}
					onSelectExistingPrintPlacementClick={
						onSelectExistingPrintPlacementClick
					}
					onCreateNewPrintPlacementClick={onCreateNewPrintPlacementClick}
					selectExistingPrintPlacementButtonText={
						selectExistingPrintPlacementButtonText
					}
					addNewPrintPlacementButtonText={addNewPrintPlacementButtonText}
					printPlacementsLabel={existingPrintPlacementsListHeading}
				/>
			),
		},
	];
	return (
		<Popover
			afterHeader={
				is.arrayWithLength(addPrintPlacementErrors?.generalErrors) ? (
					<ErrorMessageInfoBox errors={addPrintPlacementErrors.generalErrors} />
				) : null
			}
			isOpen={isOpen}
			onClose={onClose}
			onCloseDone={onCloseDone}
			pages={pages}
			currentPageIndex={currentPageIndex}
			onBackClick={onBackClick}
		/>
	);
}
ProductPrintPopover.displayName = 'ProductPrintPopover';
