/**
 * ConfirmationPageHeader
 */

import React from 'react';

import Icon from 'components/Icon';
import Img from 'components/Img';
import { Step, Stepper } from 'components/Stepper';
import Text from 'components/Text';
import { cn } from 'utils/classNames';
import { useI18n } from 'utils/i18n';

import styles from './ConfirmationPageHeader.module.css';

interface Props {
	/** React children */
	children?: React.ReactNode;

	/** The heading */
	heading: string;
}

/** The confirmation page header component */
export default function ConfirmationPageHeader({ children, heading }: Props) {
	const { t } = useI18n();
	return (
		<div className="text-center">
			<div className="md:mb-4">
				<div className="mx-4 my-8 flex items-center justify-center md:mx-0 md:my-0">
					<Img src="/images/graphics/confirmation-graphics.svg" />
				</div>
			</div>
			<Text as="h1" className="mb-4">
				{heading}
			</Text>
			{children}
			<div className="mx-auto mb-7 flex justify-center max-md:hidden md:mb-20 md:mt-14">
				<Stepper className="w-52 md:w-[34rem]">
					<Step
						label={t('order_confirmation_stepper_processing_order_label')}
						number={1}
						status="active"
					/>
					<Step
						label={t('order_confirmation_stepper_delivering_order_label')}
						number={2}
						status="pending"
					/>
					<Step
						label={t('order_confirmation_stepper_ready_for_pickup_order_label')}
						number={3}
						status="pending"
					/>
					<Step
						label={t('order_confirmation_stepper_delivered_order_label')}
						number={4}
						status="pending"
					>
						<Icon name="parcel" color="grey" />
					</Step>
				</Stepper>
			</div>
			<div
				className={cn(
					'mx-auto mb-16 mt-0 flex w-full max-w-[30rem] flex-col items-center justify-center md:hidden',
					styles.stepper,
				)}
			>
				<Step
					label={t('order_confirmation_stepper_processing_order_label')}
					number={1}
					status="active"
				/>
				<Step
					label={t('order_confirmation_stepper_delivering_order_label')}
					number={2}
					status="pending"
				/>
				<Step
					label={t('order_confirmation_stepper_ready_for_pickup_order_label')}
					number={3}
					status="pending"
				/>
				<Step
					label={t('order_confirmation_stepper_delivered_order_label')}
					number={4}
					status="pending"
				>
					<Icon name="parcel" className="text-grey" />
				</Step>
			</div>
		</div>
	);
}
ConfirmationPageHeader.displayName = 'ConfirmationPageHeader';
